/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { ErrorObject, Option } from "../../../../../../../../react-components/lu-component/src/index";
import { Radios } from "../../../../../../../../react-components/lu-component/src/index";
import {
	adCopyCategoryOrAndOptions,
	type AdCopyGender,
	type AdCopyStrategyGroupCategory,
} from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopyCategory } from "../../../../../../../../server/models/ad-copy/category";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";
import { AdCopyCheckboxes } from "../../../../../parts/ad-copy/checkboxes";
import { Help } from "../../../../../parts/help";

type Props = {
	categories: AdCopyCategory[];
	errors: ErrorObject | undefined;
	gender: keyof typeof AdCopyGender | undefined;
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
	surveyChoiceOptions: Option[];
};

export const AdCopyCategoryForm = React.memo((props: Props) => {
	const { categories, errors, gender, onChange, strategyGroup, surveyChoiceOptions } = props;

	const onChangeCategory = React.useCallback(
		(values: (boolean | number | string)[]) => {
			// 現在選択されている戦略ターゲットをまとめる。
			const strategyMap = new Map(strategyGroup.category?.map((b) => [b.surveyChoice, b]));

			onChange(
				"category",
				values
					.map((value) => {
						const v = Number(value);

						if (strategyMap.has(v)) {
							return strategyMap.get(v);
						}

						const category = categories.find((c) => c.gender === gender && c.surveyChoice.some((sc) => sc.value === v));

						return {
							category,
							surveyChoice: v,
						} as AdCopyStrategyGroupCategory;
					})
					.sort((a, b) => a?.surveyChoice - b?.surveyChoice),
			);
		},
		[categories, gender, onChange, strategyGroup.category],
	);

	return (
		<Row>
			<FormGroup as={Col} md={10}>
				<AccordionForm
					defaultShow={!!strategyGroup.category && strategyGroup.category.length !== 0}
					eventKey="category"
					title={adCopyStrategyTargetGroupLabels.category ?? ""}
				>
					<>
						<Row>
							<Col md={4}>
								<Radios
									checked={strategyGroup.categoryOrAnd}
									choices={adCopyCategoryOrAndOptions}
									name="categoryOrAnd"
									onChange={(value) => {
										onChange("categoryOrAnd", value);
									}}
								/>
							</Col>

							<Col md={1}>
								<Help
									explain={
										<div style={{ width: "30em" }}>
											例）
											<br />
											Or条件で設定するケース
											<br />
											化粧水:＜価格帯＞and＜カテゴリタイプ＞and＜使用ブランド＞
											<br />
											or
											<br />
											乳液: ＜価格帯＞and＜カテゴリタイプ＞and＜使用ブランド＞
											<br />
											<br />
											And条件で設定するケース
											<br />
											化粧水: ＜価格帯＞and＜カテゴリタイプ＞and＜使用ブランド＞
											<br />
											and
											<br />
											乳液: ＜価格帯＞and＜カテゴリタイプ＞and＜使用ブランド＞
											<br />
										</div>
									}
									id="operator-explain"
								/>
							</Col>
						</Row>

						<Row>
							<Col md={3} style={{ marginBottom: "0.6em" }} />

							<Col md={12}>
								<AdCopyCheckboxes
									checkeds={strategyGroup.category?.map((c) => c.surveyChoice) ?? []}
									choices={surveyChoiceOptions}
									disabledChoices={strategyGroup.nonPurchaseCategoryConfig?.surveyChoices.map((x) => x.value)}
									name="category"
									onChange={onChangeCategory}
								/>

								{errors && "category" in errors && typeof errors["category"] === "string" && (
									<Feedback style={{ display: "block" }} type="invalid">
										{errors["category"]}
									</Feedback>
								)}
							</Col>
						</Row>
					</>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
