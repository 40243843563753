import * as React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";

import type { ErrorObject } from "../../../../../../../react-components/lu-component/src/index";
import { TabViewer } from "../../../../../../../react-components/lu-component/src/index";
import { AdCopyConceptTypeMap } from "../../../../../../../server/models/ad-copy/concept";
import type { RetestAdCopyConcepts } from "../../../../../../../server/types/request/ad-copy/concept";
import type { TrueAdCopyConcept } from "../../../../../types/ad-copy/concept";
import type { AdCopyConceptFormFunc } from "./concept-form";
import { AdCopyConceptForm } from "./concept-form";

type Props = AdCopyConceptFormFunc & {
	cantEditMessage: string | undefined;
	concepts: TrueAdCopyConcept[];
	errors: ErrorObject | undefined;
	onPreview: () => void;
	onSubmit: () => void;
	onTemporarySave: () => void;
	retestConcepts: RetestAdCopyConcepts | undefined;
};

export const AdCopyConceptPage = React.memo((props: Props) => {
	const {
		cantEditMessage,
		concepts,
		errors,
		onPreview,
		onSubmit,
		onTemporarySave,
		retestConcepts,
		...conceptFormProps
	} = props;

	const conceptTitles = React.useMemo(() => concepts.map((concept) => AdCopyConceptTypeMap[concept.type]), [concepts]);

	const conceptErrors = React.useMemo(() => {
		if (!errors?.["concepts"]) {
			return;
		}

		const e = errors["concepts"];

		if (typeof e === "string") {
			return;
		}

		return e;
	}, [errors]);

	const tabs = React.useMemo(
		() =>
			concepts.map((concept, index) => ({
				children: (
					<AdCopyConceptForm
						concept={concept}
						retestConcepts={retestConcepts}
						{...conceptFormProps}
						errors={
							conceptErrors?.[index] && typeof conceptErrors[index] !== "string"
								? (conceptErrors[index] as ErrorObject)
								: undefined
						}
					/>
				),
				key: concept.type,
				title: AdCopyConceptTypeMap[concept.type],
			})),
		[conceptErrors, conceptFormProps, concepts, retestConcepts],
	);

	return (
		<>
			{!!cantEditMessage && (
				<div className="m-3">
					<Alert variant="danger">{cantEditMessage}</Alert>
				</div>
			)}

			<div className="m-3 clearfix">
				<Row>
					<Col md={12}>
						<div className="h3 float-start">■コピー設定</div>
					</Col>
				</Row>

				{!!conceptErrors && (
					<Row>
						<Col md={12} style={{ marginBottom: "10px" }}>
							<Alert variant="danger">
								{Object.keys(conceptErrors)
									.map((key) => conceptTitles[Number(key)])
									.join("、")}
								に対して登録エラーがあります。各タブにてご確認をお願いします。
							</Alert>
						</Col>
					</Row>
				)}

				{tabs.length && <TabViewer id="concept" tabs={tabs} />}

				<Row style={{ marginTop: "30px" }}>
					<Col md={{ offset: 2, span: 6 }}>
						{cantEditMessage ? (
							<Button className="w-100" disabled variant="secondary">
								保存できません
							</Button>
						) : (
							<Row>
								<Col md={4}>
									<Button className="w-100" onClick={onTemporarySave} variant="secondary">
										一時保存
									</Button>
								</Col>

								<Col md={4}>
									<Button className="w-100" onClick={onPreview} variant="secondary">
										一時保存＆プレビュー
									</Button>
								</Col>

								<Col md={4}>
									<Button className="w-100" onClick={onSubmit} variant="secondary">
										登録
									</Button>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</div>
		</>
	);
});
