/* eslint-disable max-params */

import type { AxiosResponse } from "axios";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { replaceBreakAndSpace } from "../../../../../../server/lib/ad-copy/common";
import type { AdCopyConceptTypeMap } from "../../../../../../server/models/ad-copy/concept";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { replaceEndpointUrl } from "../../../../../../server/router/endpoint";
import type {
	GetAdCopyConceptResponse,
	UpsertAdCopyConceptResponse,
} from "../../../../../../server/types/request/ad-copy/concept";
import { AdCopyConceptPage } from "../../../../components/pages/ad-copy/concept/create";
import type { AdCopyConceptFormFunc } from "../../../../components/pages/ad-copy/concept/create/concept-form";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { Prompt } from "../../../../components/parts/prompt";
import { get, makeError, post } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/concept";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";
import type { TrueAdCopyConcept, TrueAdCopySku } from "../../../../types/ad-copy/concept";
import { postImages } from "./post-images";

export const AdCopyConceptContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { cantEditMessage, concepts, info, loading, prompt, retestConcepts } = state;

	const { activityId, jobId } = useParams<{ activityId: string; jobId: string }>();

	const navigate = useNavigate();

	// request群
	React.useEffect(() => {
		get<GetAdCopyConceptResponse>(`${adCopyEndpoint.concept}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "initConcepts" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	const onAddSku = React.useCallback((type: keyof typeof AdCopyConceptTypeMap, index: number) => {
		dispatch({ payload: { index, type }, type: "addSku" });
	}, []);

	const onDeleteSku = React.useCallback((type: keyof typeof AdCopyConceptTypeMap, index: number) => {
		dispatch({ payload: { index, type }, type: "deleteSku" });
	}, []);

	const onChangeSku = React.useCallback(
		<T extends keyof TrueAdCopySku>(
			type: keyof typeof AdCopyConceptTypeMap,
			index: number,
			name: T,
			value: TrueAdCopySku[T],
		) => {
			dispatch({ payload: { index, name, type, value }, type: "changeSku" });
		},
		[],
	);

	const onChangeConcept = React.useCallback(
		<T extends keyof TrueAdCopyConcept>(
			type: keyof typeof AdCopyConceptTypeMap,
			name: T,
			value: TrueAdCopyConcept[T],
		) => {
			dispatch({ payload: { name, type, value }, type: "changeConcept" });
		},
		[],
	);

	const setTaxIncluded = React.useCallback(
		(type: keyof typeof AdCopyConceptTypeMap, index: number) => {
			const target = concepts.find((c) => c.type === type);

			if (!target) {
				return;
			}

			const sku = target.sku[index];

			if (!sku) {
				return;
			}

			dispatch({
				payload: {
					index,
					name: "includeTaxPrice",
					type,
					value: sku.price == null ? undefined : Math.floor((sku.price * 110) / 100),
				},
				type: "changeSku",
			});
		},
		[concepts],
	);

	const onSubmit = React.useCallback(
		async ({
			cb,
			isTemporary,
		}:
			| { cb: (response: AxiosResponse<UpsertAdCopyConceptResponse>) => void; isTemporary: true }
			| { cb?: never; isTemporary: false }) => {
			if (!jobId) {
				return;
			}

			dispatch({ payload: true, type: "changeLoading" });

			const postImagesResult = await postImages({ concepts, jobId });

			if (postImagesResult.isSuccess) {
				post<UpsertAdCopyConceptResponse>(`${adCopyEndpoint.concept}/${jobId}${isTemporary ? "?temporary=true" : ""}`, {
					concepts: postImagesResult.concepts.map((concept) => ({
						...concept,
						bodyCopies: concept.bodyCopies?.map((bodyCopy) =>
							0 < replaceBreakAndSpace(bodyCopy).length ? bodyCopy : "",
						),
					})),
				})
					.then((response) => {
						dispatch({ payload: response.data, type: "updateConcepts" });

						cb?.(response);
					})
					.catch((error) => {
						dispatch({ payload: makeError(error), type: "changeMessageInfo" });
					});
			} else {
				dispatch({
					// NOTE: strict: true ならエラー起きない
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					payload: postImagesResult.messageInfo,
					type: "changeMessageInfo",
				});
			}
		},
		[concepts, jobId],
	);

	const onTemporarySave = React.useCallback(() => {
		onSubmit({
			cb: (response) => {
				dispatch({ payload: response.data, type: "temporarySaveConcepts" });
			},
			isTemporary: true,
		});
	}, [onSubmit]);

	const navigatePreviewPage = React.useCallback(() => {
		navigate(
			replaceEndpointUrl(adCopyClientEndpoint.conceptPreview, { activityId: activityId ?? "", jobId: jobId ?? "" }),
		);
	}, [activityId, jobId, navigate]);

	const onPreview = React.useCallback(() => {
		if (cantEditMessage) {
			navigatePreviewPage();
		} else {
			onSubmit({ cb: navigatePreviewPage, isTemporary: true });
		}
	}, [cantEditMessage, navigatePreviewPage, onSubmit]);

	const onClose = React.useCallback(() => {
		navigate(replaceEndpointUrl(adCopyClientEndpoint.job, { activityId: activityId ?? "" }));
	}, [activityId, navigate]);

	const onCopy = React.useCallback<AdCopyConceptFormFunc["onCopy"]>((original, copyTo) => {
		dispatch({ payload: { copyTo, original }, type: "onCopy" });
	}, []);

	const onRetestCopy = React.useCallback<AdCopyConceptFormFunc["onRetestCopy"]>((original, copyTo) => {
		dispatch({ payload: { copyTo, original }, type: "onRetestCopy" });
	}, []);

	return (
		<>
			<Prompt message="変更が保存されていません。ページを移動してよろしいですか？" when={prompt && !loading} />

			<Loading loading={loading} />

			<ConfirmBox info={info} onClose={onClose} titleLabel="保存" />

			<AdCopyConceptPage
				cantEditMessage={cantEditMessage}
				concepts={concepts}
				errors={info.errors}
				onAddSku={onAddSku}
				onChangeConcept={onChangeConcept}
				onChangeSku={onChangeSku}
				onCopy={onCopy}
				onDeleteSku={onDeleteSku}
				onPreview={onPreview}
				onRetestCopy={onRetestCopy}
				onSubmit={() => {
					onSubmit({ isTemporary: false });
				}}
				onTemporarySave={onTemporarySave}
				retestConcepts={retestConcepts}
				setTaxIncluded={setTaxIncluded}
			/>
		</>
	);
};
