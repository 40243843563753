import { ObjectId } from "mongodb";
import { AdCopyGender, AdCopyAge } from "./activity";
import { Choice } from "@muscat/types";
import { Option } from "../../types/request";

export type CategoryUserAdCopyGeneralGroupQuota = {
	age: AdCopyAge;
	gender: keyof typeof AdCopyGender;
	sampleSize: number;
	adCopySampleSize: number;
	isUser: true;
};

export type CategoryNonUserAdCopyGeneralGroupQuota = {
	age: AdCopyAge;
	gender: keyof typeof AdCopyGender;
	sampleSize: number;
	isUser: false;
};
export type AdCopyGeneralGroupQuota = CategoryUserAdCopyGeneralGroupQuota | CategoryNonUserAdCopyGeneralGroupQuota;

/**
 * otherSkincare: 狭義スキンケア(洗顔料、メイク落とし除外)
 */
export type AdCopyCategoryType =
	| "faceWash"
	| "makeupRemover"
	| "otherSkincare"
	| "liquid-foundation"
	| "eyeShadow"
	| "lipstick"
	| "other";

type GenderKeys = keyof typeof AdCopyGender;

export const BigTitle = {
	menOnly: "男性用",
	womenOnly: "女性用",
	both: "男女共用／女性用",
} as const;

export type AdCopySurveyChoice = {
	value: number;
	label: string;
};

export type AdCopyCategorySurveyChoice = AdCopySurveyChoice & {
	bigTitle?: keyof typeof BigTitle;
	smallTitle?: string; //「あ行、か行」等の小見出し
	/** 自社ブランド対応のnBrand番号 */
	nBrand?: number;
};
export type AdCopyCategoryBrand = {
	brandName: string;
	surveyChoice: AdCopyCategorySurveyChoice[];
};

export type AdCopyCategoryRecognition = {
	nBrand: number;
	nCategoryUPI: number;
	sCategoryUPI: string;
	sBrandUPI2: string;
	gAwareness: number;
};

export interface AdCopyCategory {
	_id?: ObjectId | string;
	name: string;
	order: number;
	gender: GenderKeys;
	brandlist: AdCopyCategoryBrand[];
	type: AdCopyCategoryType;
	categoryRecognition: AdCopyCategoryRecognition[];
	generalGroupQuota: AdCopyGeneralGroupQuota[];
	surveyChoice: AdCopySurveyChoice[];
}

export const adCopyPriceList = [
	1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 8000, 10000, 15000, 20000, 25000, 30000,
];
export const adCopyPriceChoices: (Choice & {
	from: number;
	to: number | undefined;
})[] = adCopyPriceList
	.map((price, index) => {
		if (index === 0) {
			return {
				value: index + 1,
				text: `${price.toLocaleString()}円未満`,
				from: 0,
				to: price - 1,
			};
		}
		return {
			value: index + 1,
			text: `${adCopyPriceList[index - 1].toLocaleString()}～${price.toLocaleString()}円未満`,
			from: adCopyPriceList[index - 1],
			to: price - 1,
		};
	})
	.concat({
		value: adCopyPriceList.length + 1,
		text: `${adCopyPriceList[adCopyPriceList.length - 1].toLocaleString()}円以上`,
		from: adCopyPriceList[adCopyPriceList.length - 1],
		to: undefined,
	});

export const toPriceOptions: Option[] = adCopyPriceList
	.map((price) => {
		const p = price - 1;
		return {
			value: p,
			label: `${p.toLocaleString()}円`,
		};
	})
	.concat([{ value: undefined, label: "上限を設定しない" }]);

export const fromPriceOptions: Option[] = [{ value: 1, label: "1円" }].concat(
	adCopyPriceList.map((price, index) => {
		return {
			value: price,
			label: `${price.toLocaleString()}円${index - 1 === adCopyPriceList.length ? "以上" : ""}`,
		};
	}),
);

/**
 * 洗顔機能の設問設定可能か
 * warning: 洗顔 or クレンジングの識別が名称だけでしかできない
 * @param {Category} category
 */
export const canSetAdCopyFacewachFunction = (category: AdCopyCategory): boolean => {
	return isFaceWash(category) || isCleansing(category);
};

export const isFaceWash = (category: AdCopyCategory): boolean => {
	return category.name === "洗顔料";
};

export const isCleansing = (category: AdCopyCategory): boolean => {
	return category.name === "メイク落とし";
};
