import * as React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import type { AccountWithoutPassword } from "../../../../../server/models/account";
import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../routes/endpoint";

export type TopPageNavigationBarProps = {
	account: AccountWithoutPassword;
	onLogout: () => void;
	onMenuPage: () => void;
};

export const TopPageNavigationBar = ({ account, onLogout, onMenuPage }: TopPageNavigationBarProps) => {
	return (
		<Navbar bg="dark" className="px-3" expand="lg" fixed="top" variant="dark">
			<Navbar.Brand className="fw-bold">
				<NavDropdown title="Test it">
					<NavDropdown.Item href={clientEndpoint.activity}>1.コンセプトテスト</NavDropdown.Item>

					<NavDropdown.Item href={adCopyClientEndpoint.activity}>2.コピーテスト</NavDropdown.Item>
				</NavDropdown>
			</Navbar.Brand>

			<Navbar.Toggle aria-controls="basic-navbar-nav" />

			<Navbar.Collapse id="basic-navbar-nav" style={{ fontSize: 10.8 }}>
				<Nav className="me-auto" />

				<Nav>
					<Nav.Link onClick={onMenuPage}>
						<i className="bi bi-gear" style={{ fontSize: 12.8 }} />
					</Nav.Link>

					<Navbar.Text style={{ marginLeft: "20px", marginRight: "10px" }}>ログイン名: {account.name}さん</Navbar.Text>

					<Nav.Link onClick={onLogout}>Logout</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};
