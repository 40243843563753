import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

import { downloadData } from "../../../../../../lib/data";
import { get } from "../../../../../../lib/request";

const ConceptArea = styled.div`
	text-align: center;
	& img {
		width: 100%;
		max-width: 500px;
	}
	padding-bottom: 10px;
	margin-bottom: 10px;

	img.thumbnail:hover {
		cursor: pointer;
	}
`;

type Props = {
	label: string;
	url: string;
};

export const AdCopyModalImage = React.memo((props: Props) => {
	const [show, setShow] = React.useState(false);

	const { label, url } = props;

	const onDownload = React.useCallback(() => {
		get<ArrayBuffer>(url, {}, { responseType: "arraybuffer" }).then((response) => {
			const blob = new Blob([response.data], { type: "image/png" });

			downloadData(blob, `${label}.png`);
		});
	}, [url, label]);

	return (
		<>
			<div>{label}</div>

			<ConceptArea onClick={() => setShow(true)}>
				<img className="thumbnail" src={url} />
			</ConceptArea>

			<Modal
				onHide={() => {
					setShow(false);
				}}
				show={show}
			>
				<Modal.Header closeButton>
					<Modal.Title>{label}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<ConceptArea>
						<img src={url} />
					</ConceptArea>
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={onDownload} variant="secondary">
						download
					</Button>

					<Button
						onClick={() => {
							setShow(false);
						}}
						variant="outline-secondary"
					>
						close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
