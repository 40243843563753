import type { ObjectId } from "mongodb";
import * as React from "react";

import type { Option } from "../../../../../../../../../react-components/lu-component/src/index";
import type { AdCopyCategory } from "../../../../../../../../../server/models/ad-copy/category";
import { MultipleSelect } from "../../../../../../parts/select";

type Props = {
	category: AdCopyCategory;
	filteredValue?: (ObjectId | string)[];
	onChange: (values: string[]) => void;
	values?: (ObjectId | string)[];
};

export const AdCopyBrandSelect = React.memo((props: Props) => {
	const { category, filteredValue, onChange, values } = props;

	const brandOptions = React.useMemo<Option[]>(() => {
		const { brandlist } = category;

		if (!brandlist) {
			// 設定できない。
			return [];
		}

		return brandlist
			.filter((brand) => !filteredValue || !filteredValue.includes(brand.brandName))
			.map((brand) => ({
				label: brand.brandName,
				value: brand.brandName,
			}));
	}, [category, filteredValue]);

	return (
		<MultipleSelect
			onChange={(v) => {
				onChange(v as string[]);
			}}
			options={brandOptions}
			value={(values as string[]) || []}
		/>
	);
});
