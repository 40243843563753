import * as React from "react";
import { useNavigate } from "react-router-dom";

import { AccountWithoutPassword } from "../../../../../server/models/account";
import { JobStatus } from "../../../../../server/models/status";
import { PutJobStatusResponse, GetJobSearchResponse } from "../../../../../server/types/request/job";
import { GetEnqueteTestResponse } from "../../../../../server/types/request/preview";
import { Role } from "../../../../../server/lib/permission/role";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";

import { Loading } from "../../../../../react-components/lu-component/src";
import { JobSearchPage, JobSearchPageProps } from "../../../components/pages/job/search";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { JobPageType } from "../../../components/pages/job";
import { get, makeError, put, remove } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/job/search";
import { clientEndpoint } from "../../../routes/endpoint";

const getActivityId = (data: GetJobSearchResponse["data"], jobId: string) => {
	for (const d of data) {
		if (d.jobs.some((job) => job._id.toString() === jobId)) {
			return d.activity._id.toString();
		}
	}
};

export const JobSearchContainer = (loginAccount: AccountWithoutPassword): JSX.Element => {
	// states

	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, data, searches, accountOptions, result } = state;

	// hooks

	const navigate = useNavigate();

	// handlers

	const onEditPage = React.useCallback(
		(id: string) => {
			navigate(
				replaceEndpointUrl(clientEndpoint.jobCreate, {
					activityId: getActivityId(data, id),
					jobId: id,
				}),
			);
		},
		[data, navigate],
	);

	const onRetest = React.useCallback(
		(id: string) => {
			navigate(
				replaceEndpointUrl(clientEndpoint.jobCreateRetest, {
					activityId: getActivityId(data, id),
					retestJobId: id,
				}),
			);
		},
		[data, navigate],
	);

	// edit a particular job that we have fetched ig
	const onChangePage = React.useCallback(
		(id: string, page: JobPageType) => {
			if (page === "enquete-test") {
				get<GetEnqueteTestResponse>(`${endpoint.enqueteTest}/${id}`)
					.then((response) => {
						window.open(response.data.url);
					})
					.catch((error) => {
						dispatch({ type: "changeMessageInfo", payload: makeError(error) });
					});
			} else {
				navigate(`/${page}/${getActivityId(data, id)}/${id}`);
			}
		},
		[data, navigate],
	);

	// go to another page
	const onOutlinePage = React.useCallback(
		(id: string) => {
			navigate(
				replaceEndpointUrl(clientEndpoint.outline, {
					activityId: getActivityId(data, id),
					jobId: id,
				}),
			);
		},
		[data, navigate],
	);

	// go to another page
	const onAggregatePage = React.useCallback(
		(id: string) => {
			navigate(
				replaceEndpointUrl(clientEndpoint.aggtegateCreate, {
					jobId: id,
					aggregateId: "new",
				}),
			);
		},
		[navigate],
	);

	// change job
	const onJobStatusChange = React.useCallback((jobId: string, status: keyof typeof JobStatus) => {
		if (status === "deleted") {
			remove<PutJobStatusResponse>(`${endpoint.jobRoot}/${jobId}`)
				.then(() => {
					dispatch({ type: "deleteJob", payload: jobId });
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				});
		} else {
			put<PutJobStatusResponse>(`${endpoint.jobStatus}/${jobId}`, { status })
				.then((response) => {
					dispatch({
						type: "changeJobStatus",
						payload: { ...response.data, jobId },
					});
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				});
		}
	}, []);

	// change the search tags/filters/data
	const handleChangeSearches = React.useCallback<JobSearchPageProps["onChangeSearches"]>((name, value) => {
		dispatch({ type: "changeSearches", payload: { name, value } });
	}, []);

	// make this work
	const handleSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		get<GetJobSearchResponse>(endpoint.jobSearch, searches)
			.then((response) => {
				dispatch({ type: "setJob", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [searches]);

	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel="処理" />
			<JobSearchPage
				data={data}
				loginAccount={loginAccount}
				onChangeSearches={handleChangeSearches}
				onSubmit={handleSubmit}
				searches={searches}
				accountOptions={accountOptions}
				onEditPage={onEditPage}
				onChangePage={onChangePage}
				onOutlinePage={onOutlinePage}
				onJobStatusChange={onJobStatusChange}
				onRetest={onRetest}
				onAggregatePage={Role.hasOperarorManageRole(loginAccount) ? onAggregatePage : undefined}
				result={result}
			/>
		</>
	);
};
