import type { ObjectId } from "mongodb";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { replaceEndpointUrl } from "../../../../../../server/router/endpoint";
import type {
	GetAdCopyActivitiesResponse,
	LoadAdCopyActivityResponse,
} from "../../../../../../server/types/request/ad-copy/activity";
import { AdCopyActivityPage } from "../../../../components/pages/ad-copy/activity";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError, remove } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/activity/list";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";

export const AdCopyActivityListContainer = (loginAccount: AccountWithoutPassword) => {
	const navigate = useNavigate();

	const [state, dispatch] = React.useReducer(reducer, { ...initState });

	const { accounts, activities, brandOptions, categoryOptions, info, loading, searches, yearOptions } = state;

	React.useEffect(() => {
		get<LoadAdCopyActivityResponse>(adCopyEndpoint.laodActivity, state.searches)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadActivity" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
		// WARNING: KEEP THE DEPENDENCY ARRAY EMPTY - adding state.searches will break it.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeSearchValue = React.useCallback((name: string, value: number | string) => {
		dispatch({ payload: { name, value }, type: "changeSearch" });
	}, []);

	const onEditPage = React.useCallback(
		(id?: ObjectId | string) => {
			navigate(replaceEndpointUrl(adCopyClientEndpoint.activityCreate, { activityId: id ? id.toString() : "new" }));
		},
		[navigate],
	);

	const onStatusPage = React.useCallback(
		(id?: ObjectId | string) => {
			navigate(replaceEndpointUrl(adCopyClientEndpoint.activityStatus, { activityId: id ? id.toString() : "new" }));
		},
		[navigate],
	);

	const onJobListPage = React.useCallback(
		(id: ObjectId | string) => {
			navigate(replaceEndpointUrl(adCopyClientEndpoint.job, { activityId: id.toString() }));
		},
		[navigate],
	);

	const onSearch = React.useCallback(() => {
		dispatch({ payload: true, type: "changeLoading" });

		get<GetAdCopyActivitiesResponse>(adCopyEndpoint.activity, searches)
			.then((response) => {
				dispatch({ payload: response.data.activities, type: "setActivity" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [searches]);

	const onDeleteActivity = React.useCallback((id: ObjectId | string) => {
		remove(`${adCopyEndpoint.activity}/${id}`)
			.then(() => {
				dispatch({ payload: id, type: "deleteActivity" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, []);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} titleLabel="処理" />

			<AdCopyActivityPage
				accounts={accounts}
				activities={activities}
				brandOptions={brandOptions}
				categoryOptions={categoryOptions}
				loginAccount={loginAccount}
				onChangeSearchValue={onChangeSearchValue}
				onDeleteActivity={onDeleteActivity}
				onEditPage={onEditPage}
				onJobListPage={onJobListPage}
				onSearch={onSearch}
				onStatusPage={onStatusPage}
				searchValues={searches}
				yearOptions={yearOptions}
			/>
		</>
	);
};
