import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import { adCopyAestheticMedicineExperienceOptions } from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

const key = "aestheticMedicineExperience";

type Key = typeof key;

type Value = TrueAdCopyStrategyGroup[Key];

type Props = {
	onChange: (name: Key, value: Value) => void;
	value: Value | undefined;
};

export const AdCopyAestheticMedicineExperienceCheckboxes = React.memo((props: Props) => {
	const { onChange, value } = props;

	return (
		<Row>
			<FormGroup as={Col}>
				<AccordionForm defaultShow={!!value} eventKey={key} title={adCopyStrategyTargetGroupLabels[key] ?? ""}>
					<Checkboxes
						checkeds={value ?? []}
						choices={adCopyAestheticMedicineExperienceOptions as Choice[]}
						name={key}
						onChange={(value) => {
							onChange(key, value as Value);
						}}
					/>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
