import * as React from "react";
import { Card } from "react-bootstrap";

import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../routes/endpoint";
import { ButtonLink } from "../../parts/button-link";

export const TopPage = () => {
	return (
		<Card className="border-0 mx-auto my-5 py-5" style={{ maxWidth: "540px" }}>
			<ButtonLink size="lg" to={clientEndpoint.activity} variant="outline-secondary">
				1.コンセプトテスト
			</ButtonLink>

			<ButtonLink className="mt-4" size="lg" to={adCopyClientEndpoint.activity} variant="outline-secondary">
				2.コピーテスト
			</ButtonLink>
		</Card>
	);
};
