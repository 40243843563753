import { faChartLine, faCommentAlt, faDownload, faHome, faImage, faSearch } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { Container } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { AdCopyRole } from "../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../server/models/account";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import type { AuthResponse } from "../../../../../server/types/request/login";
import { AdCopyNavigationBar, type AdCopyNavigationBarProps } from "../../../components/parts/ad-copy/navigation-bar";
import type { SideMenuItem } from "../../../components/parts/sidemenu";
import { SideMenuPage } from "../../../components/parts/sidemenu";
import { post } from "../../../lib/request";
import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../routes/endpoint";

const top = 50;

const AreaWrap = styled.div<{ top: number }>`
	margin-top: ${({ top }) => top}px;
`;

type Props = {
	children: React.ReactNode | ((account: AccountWithoutPassword) => React.ReactNode);
	doNotUseTab?: boolean;
	isReportPage?: boolean;
};

export const AdCopyWithAuth = React.memo((props: Props) => {
	const { children, doNotUseTab, isReportPage } = props;

	const [requested, setRequested] = React.useState(false);

	const [account, setAccount] = React.useState<AccountWithoutPassword>();

	React.useEffect(() => {
		post<AuthResponse>(endpoint.authentication)
			.then((response) => {
				if (response.data.account) {
					setAccount(response.data.account);
				}

				setRequested(true);
			})
			.catch(() => {
				setRequested(true);
			});
	}, []);

	const navigate = useNavigate();

	const { activityId, jobId, onRetest } = useParams<{ activityId: string; jobId: string; onRetest: string }>();

	const isJobPage = (jobId || onRetest) && activityId;

	const navProps = React.useMemo<Omit<AdCopyNavigationBarProps, "account">>(
		() => ({
			jobAction:
				isJobPage && jobId !== "new" && !onRetest
					? {
							onConceptPage: () => {
								navigate(replaceEndpointUrl(adCopyClientEndpoint.concept, { activityId, jobId: jobId ?? "" }));
							},
							onJobEditPage: () => {
								navigate(replaceEndpointUrl(adCopyClientEndpoint.jobCreate, { activityId, jobId: jobId ?? "" }));
							},
							onPreviewPage: () => {
								navigate(replaceEndpointUrl(adCopyClientEndpoint.preview, { activityId, jobId: jobId ?? "" }));
							},
					  }
					: undefined,
			onActivityPage: () => {
				navigate(adCopyClientEndpoint.activity);
			},
			onJobDownloadPage:
				account && AdCopyRole.hasOperarorManageRole(account)
					? () => {
							navigate(adCopyClientEndpoint.jobDownload);
					  }
					: undefined,
			onJobPage: isJobPage
				? () => {
						navigate(replaceEndpointUrl(adCopyClientEndpoint.job, { activityId }));
				  }
				: undefined,
			onJobSearchPage: () => {
				navigate(adCopyClientEndpoint.jobSearch);
			},
			onLogout: () => {
				navigate(clientEndpoint.logout);
			},
			onMenuPage: () => {
				navigate(clientEndpoint.menu);
			},
		}),
		[account, activityId, isJobPage, jobId, navigate, onRetest],
	);

	const reportPageEvents = React.useMemo<SideMenuItem[]>(() => {
		if (!isReportPage) {
			return [];
		}

		if (!activityId || !jobId) {
			return [];
		}

		return [
			{
				icon: faHome,
				label: "テストの概要",
				onClick: () => {
					navigate(replaceEndpointUrl(adCopyClientEndpoint.outline, { activityId, jobId }));
				},
			},
			{
				icon: faChartLine,
				label: "結果の詳細",
				onClick: () => {
					navigate(replaceEndpointUrl(adCopyClientEndpoint.resultByTargetReport, { activityId, jobId }));
				},
			},
			{
				icon: faCommentAlt,
				label: "自由回答集",
				onClick: () => {
					navigate(replaceEndpointUrl(adCopyClientEndpoint.oaReport, { activityId, jobId }));
				},
			},
			{
				icon: faSearch,
				label: "集計表",
				onClick: () => {
					navigate(replaceEndpointUrl(adCopyClientEndpoint.keyMeasurementsReport, { activityId, jobId }));
				},
			},
			{
				icon: faDownload,
				label: "ダウンロード",
				onClick: () => {
					navigate(replaceEndpointUrl(adCopyClientEndpoint.downloadReport, { activityId, jobId }));
				},
			},
			{
				icon: faImage,
				label: "提示コンセプト",
				onClick: () => {
					window.open(
						replaceEndpointUrl(adCopyClientEndpoint.conceptReport, { activityId, jobId }),
						"_blank",
						"width=500,height=500",
					);
				},
				type: "popUp",
			},
		];
	}, [activityId, isReportPage, jobId, navigate]);

	return (
		requested &&
		(account ? (
			<>
				{!doNotUseTab && <AdCopyNavigationBar account={account} {...navProps} />}

				{!isReportPage && (
					<Container className="mb-5" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
						<AreaWrap className="mb-5" top={top}>
							{typeof children === "function" ? children(account) : children}
						</AreaWrap>
					</Container>
				)}

				{isReportPage && (
					<SideMenuPage items={reportPageEvents} top={top}>
						{typeof children === "function" ? children(account) : children}
					</SideMenuPage>
				)}
			</>
		) : (
			// 認証がない場合はloginへ遷移
			<Navigate to={clientEndpoint.login} />
		))
	);
});
