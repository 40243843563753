import { Labels } from "../";
import {
	AdCopyActivity,
	AdCopyGypsy,
	AdCopyNonPurchaseCategoryConfig,
	AdCopyStrategyGroup,
	AdCopyStrategyGroupCategory,
} from "../../server/models/ad-copy/activity";

export const adCopyActivityLabels: Labels<AdCopyActivity> = {
	_id: "アクティビティID",
	no: "No",
	accountIds: "アクティビティ登録者", //（アクティビティメンバー）",
	brandId: "ブランド",
	brandName: "ブランド名",
	targetCategoryBrandValues: "ターゲットカテゴリブランド",
	category: "カテゴリ",
	status: "ステータス",
	releaseYear: "上市/コピー使用開始時期（年）",
	releaseMonth: "上市/コピー使用開始時期（月）",
	salesScale: "売り上げ規模",
	grp: "TVCM規模（GRP)",
	goalRecognitionRate: "日本人（当該性の）15～69歳における想定商品認知率(%)",
	goalSalesVolume: "発売後12か月の目標お客さま購買（万個）",
	strategyTargetGroup: "戦略ターゲットグループ",
	productType: "開発区分",
	memo: "アクティビティ名　補足メモ",
};

export const adCopyStrategyTargetGroupLabels: Labels<AdCopyStrategyGroup> = {
	age: "年齢",
	married: "未既婚",
	children: "子ども年齢（同居）",
	occupation: "職業",
	skinAttribute: "肌悩み",
	sensitiveSkin: "敏感肌",
	sensitiveSkinFrequency: "敏感肌頻度（敏感肌/ときどき敏感肌のみ）",
	skinProblemFrequency: "肌トラブル頻度",
	categoryOrAnd: "条件",
	category: "過去1年購入&使用カテゴリ",
	nonPurchaseCategoryConfig: "過去1年非購入カテゴリ",
	channel: "購入チャネル",
	foundationType: "ファンデーションタイプ（リキッド）",
	facewashFunction: "洗顔機能",
	lipstickType: "口紅・リップグロス使用タイプ",
	eyeshadowType: "アイシャドー使用タイプ",
	gypsy: "化粧品選択態度：ジプシー",
	aestheticMedicineExperience: "メスを使わない美容医療利用経験",
};

export const adCopyNonPurchaseCategoryConfigLabels: Labels<AdCopyNonPurchaseCategoryConfig> = {
	surveyChoices: "調査選択肢（カテゴリ）",
	categoryOrAnd: "条件",
};

export const adCopyStrategyGroupCategoryLabels: Labels<AdCopyStrategyGroupCategory> = {
	surveyChoice: "調査選択肢（カテゴリ）",
	category: "過去1年購入&使用カテゴリ",
	price: "価格帯（税抜き）",
	beautyType: "カテゴリタイプ（スキンケアのみ）",
	usedBrand: "過去1年購入＆使用ブランド",
	unusedBrand: "過去1年非購入ブランド",
};

export const AdCopyGypsyLabels: Labels<AdCopyGypsy> = {
	surveyChoice: `ジプシー${adCopyStrategyGroupCategoryLabels.surveyChoice}`,
	beautyType: `ジプシー${adCopyStrategyGroupCategoryLabels.beautyType}`,
	facewashFunction: `ジプシー${adCopyStrategyTargetGroupLabels.facewashFunction}`,
	foundationType: `ジプシー${adCopyStrategyTargetGroupLabels.foundationType}`,
	lipstickType: `ジプシー${adCopyStrategyTargetGroupLabels.lipstickType}`,
	eyeshadowType: `ジプシー${adCopyStrategyTargetGroupLabels.eyeshadowType}`,
	gypsyChoices: "ジプシー対象選択肢",
};
