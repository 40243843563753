/* eslint-disable react/jsx-newline */

import * as React from "react";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../label/ad-copy/activity";
import { makeAdCopyAgeLabel } from "../../../../../../../server/lib/ad-copy/enquete/quota/helpers";
import type { AdCopyStrategyGroup } from "../../../../../../../server/models/ad-copy/activity";
import { AdCopyFoundationType } from "../../../../../../../server/models/ad-copy/activity";
import { AdCopyIndentArea } from "../../../../pages/ad-copy/report/outline";
import { AdCopyStrategyDetailsModalButton } from "./details-modal-button";

type Props = {
	strategyTargetGroup: AdCopyStrategyGroup;
};

export const AdCopyStrategyConditions = React.memo((props: Props) => {
	const { strategyTargetGroup } = props;

	return (
		<>
			<AdCopyStrategyDetailsModalButton strategyTargetGroup={strategyTargetGroup} />

			<AdCopyIndentArea indent={0.7}>
				<div>
					{adCopyStrategyTargetGroupLabels.age}：{makeAdCopyAgeLabel(strategyTargetGroup.age)}
				</div>

				{strategyTargetGroup.category ? (
					<>
						<div>{adCopyStrategyTargetGroupLabels.category}：</div>

						<AdCopyIndentArea indent={0.7}>
							{strategyTargetGroup.category.map((c) => {
								const surveyChoice = c.category.surveyChoice.find((sc) => sc.value === c.surveyChoice);

								const label =
									c.category.type === "liquid-foundation" &&
									strategyTargetGroup.foundationType &&
									strategyTargetGroup.foundationType.length
										? `${strategyTargetGroup.foundationType
												.map((target) => AdCopyFoundationType[target].toString().replace("　タイプ", ""))
												.join("・")}　ファンデーション`
										: surveyChoice?.label;

								return <div key={`strategy-category-${surveyChoice?.value}`}>{label}</div>;
							})}
						</AdCopyIndentArea>
					</>
				) : (
					""
				)}
			</AdCopyIndentArea>
		</>
	);
});
