import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import { adCopySensitiveSkinOptions } from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopySensitiveSkinCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	return (
		<Row>
			<FormGroup as={Col} md={6}>
				<AccordionForm
					defaultShow={!!strategyGroup.sensitiveSkin && strategyGroup.sensitiveSkin.length !== 0}
					eventKey="sensitiveSkin"
					title={adCopyStrategyTargetGroupLabels.sensitiveSkin ?? ""}
				>
					<Checkboxes
						checkeds={strategyGroup.sensitiveSkin ?? []}
						choices={adCopySensitiveSkinOptions as Choice[]}
						exclusive={[adCopySensitiveSkinOptions[adCopySensitiveSkinOptions.length - 1].value]}
						name="sensitiveSkin"
						onChange={(value) => {
							onChange("sensitiveSkin", value);
						}}
					/>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
