/* eslint-disable @typescript-eslint/naming-convention */
import { ObjectId } from "mongodb";
import { AdCopyActivityStatus } from "./status";
import { Brand } from "../brand";
import { AdCopyCategory, AdCopySurveyChoice } from "./category";

export enum AdCopySalesScale {
	moreThanBillion = "10億円以上",
	moreThan300million = "3～10億円未満",
	lessThan300million = "3億円未満",
	undecided = "未定",
}
export const AdCopySalesScaleOptions = Object.entries(AdCopySalesScale)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyGrp {
	moreThan1000 = "1000GRP以上",
	lessThan1000 = "1000GRP未満",
	none = "なし",
	undecided = "未定",
}
export const AdCopyGrpOptions = Object.entries(AdCopyGrp)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyGender {
	male = "男性",
	female = "女性",
}
export const genderOptions = Object.entries(AdCopyGender)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export interface AdCopyAge {
	from: number;
	to: number;
}
export interface AdCopyPrice {
	from?: number;
	to?: number;
	label?: string;
}

export enum AdCopyMarried {
	unmarried = "未婚（離死別も含む）",
	married = "既婚",
}
export const marriedAdCopyOptions = Object.entries(AdCopyMarried)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyChildren {
	preschool = "子ども（未就学児/0歳～6歳）",
	elementarySchool = "子ども（小学生/6歳～12歳）",
	juniorHighSchool = "子ども（中学生/12歳～15歳",
	highSchool = "子ども（高校生/15歳～18歳）",
	university = "子ども（大学生以上/18歳以上）",
}
export const childrenAdCopyOptions = Object.entries(AdCopyChildren)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyOccupation {
	employed = "有職（パート・アルバイト含む）",
	notEmployed = "無職",
	student = "学生",
}
export const occupationAdCopyOptions = Object.entries(AdCopyOccupation)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export const adCopySkinAttributeOptionsWithBlock = [
	{ blockName: "A", value: 1, label: "シミ・そばかす" },
	{ blockName: "B", value: 2, label: "毛穴の目立ち" },
	{ blockName: "B", value: 3, label: "頬のたるみ" },
	{ blockName: "B", value: 4, label: "頬のしわ" },
	{ blockName: "C", value: 5, label: "乾燥・かさつき・うるおいのなさ" },
	{ blockName: "C", value: 6, label: "ごわつき" },
	{ blockName: "C", value: 7, label: "皮むけ" },
	{ blockName: "F", value: 8, label: "ぶつぶつ" },
	{ blockName: "F", value: 9, label: "かゆみ" },
	{ blockName: "F", value: 10, label: "かぶれ" },
	{ blockName: "F", value: 11, label: "湿疹" },
	{ blockName: "F", value: 12, label: "ただれ" },
	{ blockName: "F", value: 13, label: "皮膚炎" },
	{ blockName: "D", value: 14, label: "ニキビ・吹き出物" },
	{ blockName: "A", value: 15, label: "くすみ" },
	{ blockName: "A", value: 16, label: "透明感のなさ" },
	{ blockName: "E", value: 17, label: "目の下のくま" },
	{ blockName: "E", value: 18, label: "目元のしわ・たるみ" },
	{ blockName: "F", value: 19, label: "肌荒れ" },
	{ blockName: "D", value: 20, label: "脂っぽさ・テカリ・べたつき" },
	{ blockName: "C", value: 21, label: "きめの粗さ" },
	{ blockName: "C", value: 22, label: "つやのなさ" },
	{ blockName: "F", value: 23, label: "頬の赤み" },
	{ blockName: "A", value: 24, label: "色むら" },
	{ blockName: "F", value: 25, label: "アトピー/肌のアレルギー" },
	{ blockName: "F", value: 26, label: "花粉/PM2.5/ハウスダストなどによる肌荒れ" },
] as const;

/** ブロック間・ブロック内ランダム提示 */
export enum AdCopySkinAttribute {
	"シミ・そばかす",
	"毛穴の目立ち",
	"頬のたるみ",
	"頬のしわ",
	"乾燥・かさつき・うるおいのなさ",
	"ごわつき",
	"皮むけ",
	"ぶつぶつ",
	"かゆみ",
	"かぶれ",
	"湿疹",
	"ただれ",
	"皮膚炎",
	"ニキビ・吹き出物",
	"くすみ",
	"透明感のなさ",
	"目の下のくま",
	"目元のしわ・たるみ",
	"肌荒れ",
	"脂っぽさ・テカリ・べたつき",
	"きめの粗さ",
	"つやのなさ",
	"頬の赤み",
	"色むら",
	"アトピー/肌のアレルギー",
	"花粉/PM2.5/ハウスダストなどによる肌荒れ",
}

export const adCopySkinAttributeOptions = Object.entries(AdCopySkinAttribute)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export const adCopySkinAttributeExcludeOtherOptions = adCopySkinAttributeOptions.filter(({ label }) => {
	return label !== "その他" && label !== "とくに悩みはない";
});

export enum AdCopySensitiveSkin {
	"敏感肌",
	"ときどき敏感肌",
	"敏感肌ではない",
}

export const adCopySensitiveSkinOptions = Object.entries(AdCopySensitiveSkin)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

/**
 * enum index
 *  0: 頻度高：週に1回以上
 *  1: 頻度中：月に1-2回
 * 2: 頻度低：それ以下
 */
export enum AdCopySensitiveSkinFrequencyRank {
	"頻度高：週に1回以上",
	"頻度中：月に1-2回",
	"頻度低：それ以下",
}

export const adCopySensitiveSkinFrequencyRankOptions = Object.entries(AdCopySensitiveSkinFrequencyRank)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export const adCopySensitiveSkinFrequencyOptionsWithRank = [
	{ value: 1, label: "ほぼ毎日", rank: 0 },
	{ value: 2, label: "週に２～３回程度", rank: 0 },
	{ value: 3, label: "週に１回程度", rank: 0 },
	{ value: 4, label: "月に２回程度", rank: 1 },
	{ value: 5, label: "月に１回程度", rank: 1 },
	{ value: 6, label: "２か月に１回程度", rank: 2 },
	{ value: 7, label: "３か月に１回程度", rank: 2 },
	{ value: 8, label: "半年に１回程度", rank: 2 },
	{ value: 9, label: "それ以下", rank: 2 },
];

export const adCopySensitiveSkinFrequencyOptions = adCopySensitiveSkinFrequencyOptionsWithRank.map(
	({ label, value }) => {
		return { value, label };
	},
);

export enum AdCopySkinProblemFrequency {
	"定期的に肌荒れを起こすことがある（生理前・季節の変わり目などに起こす肌荒れ）",
	"一時的な肌荒れを起こすことがある（ストレス、食生活の乱れなどによる肌荒れ）",
	"基本的に肌荒れはしない",
}

export const adCopySkinProblemFrequencyOptions = Object.entries(AdCopySkinProblemFrequency)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyCategoryOrAnd {
	Or = "カテゴリ間の条件をorで設定する",
	And = "カテゴリ間の条件をandで設定する",
}
export const adCopyCategoryOrAndOptions = Object.entries(AdCopyCategoryOrAnd)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyChannel {
	"デパート・百貨店（三越、高島屋、伊勢丹、大丸など）にある化粧品フロアのカウンター",
	"デパート・百貨店（三越、高島屋、伊勢丹、大丸など）に出店している化粧品店",
	"大型ショッピングモール（イオンモール、ららぽーと、アウトレットモールなど）に出店している化粧品店",
	"総合スーパー（イオン、イトーヨーカドー、ダイエーなど）のセルフ販売形式の化粧品売場",
	"総合スーパー（イオン、イトーヨーカドー、ダイエーなど）にある化粧品フロアのカウンター",
	"総合スーパー（イオン、イトーヨーカドー、ダイエーなど）に出店している化粧品店",
	"ドラッグストア（マツモトキヨシ、ココカラファイン、ツルハドラッグなどのチェーン店）",
	"薬局・薬店（個人経営や調剤薬局など）",
	"化粧品店①（※駅ビルや商業施設、地下街などの専門店街に入っているお店）",
	"化粧品店②（※町の化粧品店など路面店タイプ）",
	"ブランド直営店（ザ・ボディショップ、ロクシタン、ファンケル、ＤＨＣ、オルビスなど）",
	"バラエティストア（PLAZA、ショップイン、東急ハンズ、Loftなど）",
	"ファッション・インテリア・雑貨系ショップ（無印良品、ZARA、Francfrancなど）",
	"ディスカウントストア／ホームセンター（ドン・キホーテ、カインズホームなど）",
	"コンビニエンスストア（セブンイレブン、ローソン、ファミリーマートなど）",
	"ダイソーやセリア、3コインズなどの●●円均一ショップ",
	"免税店・機内販売",
	"家電量販店",
	"スパ／エステ／美容院",
	"皮膚科／美容クリニック",
	"通信販売（テレビショッピング／カタログ／メールオーダー）（ショップジャパンやディノスなど）",
	"インターネット・ＷＥＢ（メーカーまたはブランドの直販サイト）",
	"インターネット・ＷＥＢ（小売業者サイト［楽天市場、アマゾン、Ｙａｈｏｏ！ショッピング、ネットスーパーなど］、ヤフオク！など）",
	"訪問販売",
	"その他",
}

export const adCopyChannelOptions = Object.entries(AdCopyChannel)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export const adCopyChannelExcludeOtherOptions = adCopyChannelOptions.filter(({ label }) => {
	return label !== "その他";
});

export enum AdCopyBeautyType {
	"保湿",
	"美白",
	"エイジングケア",
	"ニキビ・アクネケア",
	"敏感肌対策",
	"UV効果（SPFやPA付き）",
	"その他",
}
export const adCopyBeautyTypeOptions = Object.entries(AdCopyBeautyType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyFoundationType {
	"リキッド　タイプ",
	"クッション　タイプ",
	"クリーム　タイプ",
	"エマルジョン　タイプ",
	"その他",
}

export const adCopyFoundationTypeOptions = Object.entries(AdCopyFoundationType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyFacewashFunction {
	"洗顔機能のみの商品",
	"メイク落とし機能のみの商品",
	"洗顔～メイク落としまでできる商品（ダブル洗顔不要）",
	"その他",
}
export const adCopyFacewashFunctionOptions = Object.entries(AdCopyFacewashFunction)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyAestheticMedicineExperience {
	"受けたことがあり、今後も継続したい",
	"受けたことがあるが、継続する意思はない",
	"受けたことがないが、興味はある",
	"受けたことがないし、興味もない",
}

export const adCopyAestheticMedicineExperienceOptions = Object.entries(AdCopyAestheticMedicineExperience)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyLipstickType {
	"スティック",
	"リキッドルージュ",
	"リップグロス",
	"ティント",
	"その他",
}

export const adCopyLipstickTypeOptions = Object.entries(AdCopyLipstickType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyEyeshaowType {
	"単色アイシャドー",
	"パレットアイシャドー",
	"その他",
}

export const adCopyEyeshaowTypeOptions = Object.entries(AdCopyEyeshaowType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyGypsyChoice {
	"決まった商品・ブランドがあり愛用している",
	"決まった商品・ブランドはなく、その時々でより効果がありそうなものを探して選んでいる",
	"決まった商品・ブランドはないが、あまり考えずにその時の気持ちで選んでいる",
	"決まった商品・ブランドはなく、その時の価格で選んでいる",
	"わからない／この中にはひとつもない",
}

export const adCopyGypsyOptions = Object.entries(AdCopyGypsyChoice)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export const adCopyGypsyExcludeOtherOptions = adCopyGypsyOptions.filter(({ label }) => {
	return label !== "わからない／この中にはひとつもない";
});

export type AdCopyGypsy = {
	surveyChoice: AdCopySurveyChoice;
	beautyType?: keyof typeof AdCopyBeautyType;
	facewashFunction?: keyof typeof AdCopyFacewashFunction;
	foundationType?: keyof typeof AdCopyFoundationType;
	gypsyChoices: (keyof typeof AdCopyGypsyChoice)[];
	lipstickType?: keyof typeof AdCopyLipstickType;
	eyeshadowType?: keyof typeof AdCopyEyeshaowType;
};

export type AdCopyStrategyGroupCategory = {
	category: AdCopyCategory;
	surveyChoice: number;
	price?: AdCopyPrice;
	/** 美類タイプ */
	beautyType?: (keyof typeof AdCopyBeautyType)[];
	/** 過去１年使用ブランド */
	usedBrand?: string[]; // brandName
	/** 過去１年未使用ブランド */
	unusedBrand?: string[]; // brandName
};

/**
 *
 * 過去一年非購入カテゴリ設定
 */
export type AdCopyNonPurchaseCategoryConfig = {
	surveyChoices: AdCopySurveyChoice[];
	categoryOrAnd: keyof typeof AdCopyCategoryOrAnd;
};

export type AdCopyStrategyGroup = {
	age: AdCopyAge;
	// /** 未既婚 */
	married?: (keyof typeof AdCopyMarried)[];
	// /** 子ども有無 */
	children?: (keyof typeof AdCopyChildren)[];
	// /** 職業 */
	occupation?: (keyof typeof AdCopyOccupation)[];
	/** 肌悩み */
	skinAttribute?: (keyof typeof AdCopySkinAttribute)[];
	/** 敏感肌① */
	sensitiveSkin?: (keyof typeof AdCopySensitiveSkin)[];
	/** 敏感肌頻度区分② */
	sensitiveSkinFrequency?: (keyof typeof AdCopySensitiveSkinFrequencyRank)[];
	categoryOrAnd: keyof typeof AdCopyCategoryOrAnd;
	category?: AdCopyStrategyGroupCategory[];

	/** 過去一年非購入カテゴリ設定 */
	nonPurchaseCategoryConfig?: AdCopyNonPurchaseCategoryConfig;

	/** 購入チャネル */
	channel?: (keyof typeof AdCopyChannel)[];
	/** リキッドファンデーション使用カテゴリ */
	foundationType?: (keyof typeof AdCopyFoundationType)[];
	/** 洗顔・メイク落とし使用機能 */
	facewashFunction?: keyof typeof AdCopyFacewashFunction;
	/** 口紅・リップグロス使用カテゴリ */
	lipstickType?: (keyof typeof AdCopyLipstickType)[];
	/** アイシャドー使用カテゴリ */
	eyeshadowType?: (keyof typeof AdCopyEyeshaowType)[];
	/** 肌トラブル頻度 */
	skinProblemFrequency?: (keyof typeof AdCopySkinProblemFrequency)[];
	/** ジプシー */
	gypsy?: AdCopyGypsy;
	/** 美容医療利用経験 */
	aestheticMedicineExperience?: (keyof typeof AdCopyAestheticMedicineExperience)[];
};

export enum AdCopyProductType {
	newItem = "新商品",
	renewal1 = "リニューアル（外装強化＆中味強化）",
	renewal2 = "リニューアル（外装強化のみ）",
	existingProductAdd = "既存品の色調/香調/容量等追加",
	existingProductOnlyComminication = "既存品のコミュニケーションのみ開発",
	other = "その他",
}

export const productTypeOptions = Object.entries(AdCopyProductType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export type AdCopySimpleActivity = {
	_id?: ObjectId | string;
	no?: number;
	accountIds: (string | ObjectId)[];
	brandId: ObjectId | string;
	/** 新規ブランドのみ入力可 */
	brandName?: string;
	/** ブランドユーザー対象定義用。ブランドリストの中のブランドの選択肢value */
	targetCategoryBrandValues?: number[];
	status: keyof typeof AdCopyActivityStatus;
	releaseYear: number;
	releaseMonth: number;
	salesScale: keyof typeof AdCopySalesScale;
	grp: keyof typeof AdCopyGrp;
	goalRecognitionRate: number;
	goalSalesVolume: number;
	category: AdCopyCategory;
	productType: keyof typeof AdCopyProductType;
	memo?: string;
	createdAt?: string;
	updatedAt?: string;
};

export type AdCopyActivity = AdCopySimpleActivity & {
	strategyTargetGroup: AdCopyStrategyGroup;
};

export type AdCopyActivityWithBrand = AdCopyActivity & {
	brand: Brand;
};

export const borderAppearance = 3;
