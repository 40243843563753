import * as React from "react";
import { Container } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";

import type { AccountWithoutPassword } from "../../../../server/models/account";
import { endpoint } from "../../../../server/router/endpoint";
import type { AuthResponse } from "../../../../server/types/request/login";
import type { TopPageNavigationBarProps } from "../../components/parts/top-page-navigation-bar";
import { TopPageNavigationBar } from "../../components/parts/top-page-navigation-bar";
import { post } from "../../lib/request";
import { clientEndpoint } from "../../routes/endpoint";

const top = 50;

const AreaWrap = styled.div<{ top: number }>`
	margin-top: ${({ top }) => top}px;
`;

type Props = {
	children: React.ReactNode | ((account: AccountWithoutPassword) => React.ReactNode);
};

export const TopPageWithAuth = React.memo((props: Props) => {
	const { children } = props;

	const [requested, setRequested] = React.useState(false);

	const [account, setAccount] = React.useState<AccountWithoutPassword>();

	React.useEffect(() => {
		post<AuthResponse>(endpoint.authentication)
			.then((response) => {
				if (response.data.account) {
					setAccount(response.data.account);
				}

				setRequested(true);
			})
			.catch(() => {
				setRequested(true);
			});
	}, []);

	const navigate = useNavigate();

	const navProps = React.useMemo<Omit<TopPageNavigationBarProps, "account">>(() => {
		return {
			onLogout: () => {
				navigate(clientEndpoint.logout);
			},
			onMenuPage: () => {
				navigate(clientEndpoint.menu);
			},
		};
	}, [navigate]);

	return (
		requested &&
		(account ? (
			<>
				<TopPageNavigationBar account={account} {...navProps} />

				<Container className="mb-5" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
					<AreaWrap className="mb-5" top={top}>
						{typeof children === "function" ? children(account) : children}
					</AreaWrap>
				</Container>
			</>
		) : (
			// 認証がない場合はloginへ遷移
			<Navigate to={clientEndpoint.login} />
		))
	);
});
