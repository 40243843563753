/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import {
	adCopyStrategyGroupCategoryLabels,
	adCopyStrategyTargetGroupLabels,
} from "../../../../../../../../label/ad-copy/activity";
import type { Choice, Option } from "../../../../../../../../react-components/lu-component/src/index";
import { Radios } from "../../../../../../../../react-components/lu-component/src/index";
import type {
	AdCopyBeautyType,
	AdCopyEyeshaowType,
	AdCopyFoundationType,
	AdCopyGypsy,
	AdCopyLipstickType,
} from "../../../../../../../../server/models/ad-copy/activity";
import {
	adCopyBeautyTypeOptions,
	adCopyEyeshaowTypeOptions,
	AdCopyFacewashFunction,
	adCopyFacewashFunctionOptions,
	adCopyFoundationTypeOptions,
	AdCopyGypsyChoice,
	adCopyLipstickTypeOptions,
} from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopySurveyChoice } from "../../../../../../../../server/models/ad-copy/category";
import {
	canSetAdCopyFacewachFunction,
	isCleansing,
	isFaceWash,
} from "../../../../../../../../server/models/ad-copy/category";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";
import { emptyToUndefined, isSkincare } from "./utils";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
	surveyChoiceOptions: Option[];
};

export const AdCopyGypsyForms = React.memo((props: Props) => {
	const { onChange, strategyGroup, surveyChoiceOptions } = props;

	const strategyGroupCategories = strategyGroup.category;

	const gypsy = strategyGroup.gypsy;

	const selectedStrategyGroupCategory = React.useMemo(
		() => strategyGroupCategories?.find((c) => c.surveyChoice === gypsy?.surveyChoice.value),
		[gypsy?.surveyChoice.value, strategyGroupCategories],
	);

	return (
		<Row>
			<FormGroup as={Col}>
				<AccordionForm defaultShow={gypsy != null} eventKey="gypsy" title={adCopyStrategyTargetGroupLabels.gypsy ?? ""}>
					{strategyGroupCategories != null && 0 < strategyGroupCategories.length ? (
						<>
							<Col md={12}>
								下記指定カテゴリで「
								{
									AdCopyGypsyChoice[
										AdCopyGypsyChoice[
											"決まった商品・ブランドはなく、その時々でより効果がありそうなものを探して選んでいる"
										]
									]
								}
								」人を条件設定
							</Col>

							<Col>
								<Radios
									checked={gypsy?.surveyChoice.value ?? ""}
									choices={surveyChoiceOptions.filter((x) =>
										strategyGroupCategories.some((y) => y.surveyChoice === x.value),
									)}
									name="gypsy-surveyChoice"
									onChange={(value) => {
										onChange("gypsy", {
											gypsyChoices: [
												AdCopyGypsyChoice[
													"決まった商品・ブランドはなく、その時々でより効果がありそうなものを探して選んでいる"
												].toString() as keyof typeof AdCopyGypsyChoice,
											],
											surveyChoice: surveyChoiceOptions.find((x) => x.value === value) as AdCopySurveyChoice,
										} satisfies AdCopyGypsy);
									}}
								/>
							</Col>

							{gypsy != null && selectedStrategyGroupCategory != null && (
								<Row className="mt-4 px-3">
									{/* カテゴリタイプ（スキンケアのみ） */}
									{/* 美類タイプ */}
									{isSkincare(selectedStrategyGroupCategory.category.type) && (
										<Col md={3}>
											{adCopyStrategyGroupCategoryLabels.beautyType}

											<Radios
												checked={gypsy.beautyType ?? ""}
												choices={[
													...(adCopyBeautyTypeOptions.filter(
														(x) =>
															selectedStrategyGroupCategory.beautyType?.includes(
																x.value as keyof typeof AdCopyBeautyType,
															) && x.label !== "その他",
													) as Choice[]),
													{ label: "問わない", value: "" },
												]}
												name="gypsy-beautyType"
												onChange={(value) => {
													onChange("gypsy", {
														...gypsy,
														beautyType: emptyToUndefined(value as keyof typeof AdCopyBeautyType),
													} satisfies AdCopyGypsy);
												}}
											/>
										</Col>
									)}

									{/* 洗顔機能 */}
									{canSetAdCopyFacewachFunction(selectedStrategyGroupCategory.category) && (
										<Col>
											{adCopyStrategyTargetGroupLabels.facewashFunction}

											<Radios
												checked={gypsy.facewashFunction ?? ""}
												choices={[
													...(adCopyFacewashFunctionOptions.filter(
														(x) =>
															x.value === strategyGroup.facewashFunction &&
															(x.value === AdCopyFacewashFunction.洗顔機能のみの商品.toString()
																? isFaceWash(selectedStrategyGroupCategory.category)
																: true) &&
															(x.value === AdCopyFacewashFunction.メイク落とし機能のみの商品.toString()
																? isCleansing(selectedStrategyGroupCategory.category)
																: true),
													) as Choice[]),
													{ label: "問わない", value: "" },
												]}
												name="gypsy-facewashFunction"
												onChange={(value) => {
													onChange("gypsy", {
														...gypsy,
														facewashFunction: emptyToUndefined(value as keyof typeof AdCopyFacewashFunction),
													} satisfies AdCopyGypsy);
												}}
											/>
										</Col>
									)}

									{/* ファンデーションタイプ */}
									{selectedStrategyGroupCategory.category.type === "liquid-foundation" && (
										<Col>
											{adCopyStrategyTargetGroupLabels.foundationType}

											<Radios
												checked={gypsy.foundationType ?? ""}
												choices={[
													...(adCopyFoundationTypeOptions.filter(
														(x) => strategyGroup.foundationType?.includes(x.value as keyof typeof AdCopyFoundationType),
													) as Choice[]),
													{ label: "問わない", value: "" },
												]}
												name="gypsy-foundationType"
												onChange={(value) => {
													onChange("gypsy", {
														...gypsy,
														foundationType: emptyToUndefined(value as keyof typeof AdCopyFoundationType),
													} satisfies AdCopyGypsy);
												}}
											/>
										</Col>
									)}

									{/* 口紅・リップグロス使用カテゴリ */}
									{selectedStrategyGroupCategory.category.type === "lipstick" && (
										<Col>
											{adCopyStrategyTargetGroupLabels.lipstickType}

											<Radios
												checked={gypsy.lipstickType ?? ""}
												choices={[
													...(adCopyLipstickTypeOptions.filter(
														(x) => strategyGroup.lipstickType?.includes(x.value as keyof typeof AdCopyLipstickType),
													) as Choice[]),
													{ label: "問わない", value: "" },
												]}
												name="gypsy-lipstickType"
												onChange={(value) => {
													onChange("gypsy", {
														...gypsy,
														lipstickType: emptyToUndefined(value as keyof typeof AdCopyLipstickType),
													} satisfies AdCopyGypsy);
												}}
											/>
										</Col>
									)}

									{/* アイシャドー使用カテゴリ */}
									{selectedStrategyGroupCategory.category.type === "eyeShadow" && (
										<Col>
											{adCopyStrategyTargetGroupLabels.eyeshadowType}

											<Radios
												checked={gypsy.eyeshadowType ?? ""}
												choices={[
													...(adCopyEyeshaowTypeOptions.filter(
														(x) => strategyGroup.eyeshadowType?.includes(x.value as keyof typeof AdCopyEyeshaowType),
													) as Choice[]),
													{ label: "問わない", value: "" },
												]}
												name="gypsy-eyeshadowType"
												onChange={(value) => {
													onChange("gypsy", {
														...gypsy,
														eyeshadowType: emptyToUndefined(value as keyof typeof AdCopyEyeshaowType),
													} satisfies AdCopyGypsy);
												}}
											/>
										</Col>
									)}
								</Row>
							)}
						</>
					) : (
						<></>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
