import { AggregateTargetQuestion } from "@analytics/types";
import { ChoiceGroup, PageConfig, Question, WrapQuestion } from "@muscat/types";
import { AdCopyStrategyGroup, adCopySkinAttributeOptionsWithBlock } from "../../../models/ad-copy/activity";
import { AdCopyConceptKeyForQuota, AdCopySku } from "../../../models/ad-copy/concept";
import { AdCopyJob } from "../../../models/ad-copy/job";
import { isDefined, isNotEmptyArray } from "../../common";
import { AdCopyGeneralGroupQuota, CategoryUserAdCopyGeneralGroupQuota } from "../../../models/ad-copy/category";
import { adCopyFlagCommonValue, adCopyQuotaCodeMap, isCategoryUserAdCopyQuename } from "./config";
import { makeAgeCondition } from "./quota/helpers";

// 構成がおかしい以外出ないエラー。
export class ConceptQuestionError extends Error {
	constructor(...params: any[]) {
		super(...params);
		Object.setPrototypeOf(this, ConceptQuestionError.prototype);
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ConceptQuestionError);
		}
		this.name = "ConceptQuestionError";
	}
}

export const getTargetQuestionIndex = (questionnaire: PageConfig[], quename: string) => {
	for (let pageIndex = 0; pageIndex < questionnaire.length; pageIndex++) {
		const wrapQuestion = questionnaire[pageIndex];
		if (!("questions" in wrapQuestion)) {
			continue;
		}
		for (let queIndex = 0; queIndex < wrapQuestion.questions.length; queIndex++) {
			const q = wrapQuestion.questions[queIndex];
			if (quename === q.quename) {
				return { pageIndex, queIndex };
			}
		}
	}
	return undefined;
};

export const makeQuestionArray = (wrapQuestions: WrapQuestion[]): Question[] => {
	return wrapQuestions.reduce((a, { questions }) => {
		for (const question of questions) {
			a.push(question);
		}
		return a;
	}, []);
};

export const makeAggregateQuestions = (questionnaire: PageConfig[]): AggregateTargetQuestion[] => {
	return extractWrapQuestions(questionnaire).reduce((a: AggregateTargetQuestion[], { questions }) => {
		return [
			...a,
			...questions.filter(
				(question): question is AggregateTargetQuestion =>
					!(question.type === "E" || question.type === "FI" || question.type === "O"),
			),
		];
	}, []);
};

/**
 * アンケートの設問部分(pageConfigのwrapQuestionのみ)をを抽出する。
 */
export const extractWrapQuestions = (questionnaire: PageConfig[]): WrapQuestion[] => {
	return questionnaire.filter((pageConfig): pageConfig is WrapQuestion => {
		return "questions" in pageConfig;
	});
};

export const makeAdCopySkinAttributeChoiceGroups = () => {
	const adCopySkinAttributeChoiceGroups: ChoiceGroup[] = [];

	const blockNames = Array.from(new Set(adCopySkinAttributeOptionsWithBlock.map((option) => option.blockName)));

	const adCopySkinAttributeGroupByBlockName = blockNames.map((blockName) => {
		return adCopySkinAttributeOptionsWithBlock.filter((option) => option.blockName === blockName);
	});

	adCopySkinAttributeGroupByBlockName.forEach((options, index) => {
		adCopySkinAttributeChoiceGroups.push({
			id: index + 1,
			choices: options.map((option) => {
				return { value: option.value, text: option.label };
			}),
		});
	});
	return adCopySkinAttributeChoiceGroups;
};

/**
 * ●●●●（SKU名＆容量）　〇〇〇〇円（税抜）／〇〇〇〇円（税込）
 * ●●●●（SKU名＆容量）　〇〇〇〇円（税抜）／〇〇〇〇円（税込）
 */
export const makeAdCopySkuSentence = (sku: AdCopySku[]) => {
	const skuSentences: string[] = [];
	for (const skuItem of sku) {
		const sentence = `${skuItem.name}　${skuItem.price}円（税抜）／${skuItem.includeTaxPrice}円（税込）`;
		skuSentences.push(sentence);
	}
	return skuSentences.join("<br>");
};

/**
 * アンケートのpage group部分index
 */
export const getPageGroupIndex = (questionnaire: PageConfig[]): number => {
	for (let i = 0; i < questionnaire.length; i++) {
		if ("grouping" in questionnaire[i]) {
			return i;
		}
	}
	throw new ConceptQuestionError("page groupが見つかりませんでした。");
};

export const makeRanges = (min: number, max: number, step = 1): number[] => {
	const ranges = [];
	for (let i = min; i <= max; i += step) {
		ranges.push(i);
	}
	return ranges;
};

export const make3digitQuotaCode = (codeNumber: number) => {
	return codeNumber.toString().padStart(3, "0");
};

const isCategoryUserAdCopyGeneralGroupQuota = (
	quota: AdCopyGeneralGroupQuota,
): quota is CategoryUserAdCopyGeneralGroupQuota => {
	return quota.isUser === true;
};

export const getCategoryGeneralGroupUserQuotas = (
	generalGroupQuota: AdCopyGeneralGroupQuota[],
): CategoryUserAdCopyGeneralGroupQuota[] => {
	return generalGroupQuota.filter((quota): quota is CategoryUserAdCopyGeneralGroupQuota =>
		isCategoryUserAdCopyGeneralGroupQuota(quota),
	);
};

/**
 *  C001-001 ~ C001-022
 */
export const makeCategoryUserQuotaCode = (conceptNumber: number, ageIndex: number) => {
	return `${adCopyQuotaCodeMap.categoryUser}${make3digitQuotaCode(conceptNumber)}-${make3digitQuotaCode(ageIndex + 1)}`;
};

/**
 * 割付用のコンセプト種類を取得する
 */
export const getConceptTypesForQuota = (job: AdCopyJob): AdCopyConceptKeyForQuota[] => {
	const conceptTypes: AdCopyConceptKeyForQuota[] = ["test1"];
	if (job.numOfTests >= 2) conceptTypes.push("test2");
	if (job.numOfTests >= 3) conceptTypes.push("test3");
	if (job.hasCurrentProduct) conceptTypes.push("currentProduct");
	return conceptTypes;
};

/**
 * 割付コード番号付け用のコンセプト対応数字を取得する
 */
export const getConceptNumbersForQuota = (job: AdCopyJob): number[] => {
	const conceptNumbers = [1];
	if (job.numOfTests >= 2) conceptNumbers.push(2);
	if (job.numOfTests >= 3) conceptNumbers.push(3);
	if (job.hasCurrentProduct) conceptNumbers.push(4);
	return conceptNumbers;
};

/**
 * 肌悩みの設問を戦略ターゲットで設定しているか。
 * @returns {boolean}
 */
export function hasSkinAttribute(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isNotEmptyArray(strategyTargetGroup.skinAttribute);
}

/**
 * 敏感肌①の設問を戦略ターゲットで設定しているか。
 * @returns {boolean}
 */
export function hasSensitiveSkin(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isNotEmptyArray(strategyTargetGroup.sensitiveSkin);
}

/**
 * 敏感肌②の設問を戦略ターゲットで設定しているか。
 * @returns {boolean}
 */
export function hasSensitiveSkinFrequency(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isNotEmptyArray(strategyTargetGroup.sensitiveSkinFrequency);
}

/**
 * 肌トラブル頻度の設問を戦略ターゲットで設定しているか。
 * @returns {boolean}
 */
export function hasSkinProblemFrequency(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isNotEmptyArray(strategyTargetGroup.skinProblemFrequency);
}

/**
 * 洗顔・メイク落とし使用機能の設問を戦略ターゲットで設定しているか。
 */
export function hasFacewashOrCleansing(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isDefined(strategyTargetGroup.facewashFunction);
}

/**
 * 以下条件の場合はliquid foundationの設問を生成する
 * - 戦略ターゲットでliquid foundationのカテゴリを設定している
 * @returns {boolean}
 */
export function hasFoundationType(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isNotEmptyArray(strategyTargetGroup.foundationType);
}

/**
 * 口紅・リップグロス使用カテゴリの設問を戦略ターゲットで設定しているか。
 * @returns {boolean}
 */
export function hasLipstickType(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isNotEmptyArray(strategyTargetGroup.lipstickType);
}

/**
 * アイシャドー使用カテゴリの設問を戦略ターゲットで設定しているか。
 * @returns {boolean}
 */
export function hasEyeshadowType(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isNotEmptyArray(strategyTargetGroup.eyeshadowType);
}

/**
 * カテゴリタイプの設問を戦略ターゲットで設定しているか。
 * @returns {boolean}
 */
export function hasBeautyType(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return strategyTargetGroup.category.some((st) => {
		return isNotEmptyArray(st.beautyType);
	});
}

/**
 * ジプシーの設問を戦略ターゲットで設定しているか。
 */
export function hasGypsy(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isDefined(strategyTargetGroup.gypsy);
}

/**
 * 美容医療　利用経験の設問を戦略ターゲットで設定しているか。
 */
export function hasAestheticMedicineExperience(strategyTargetGroup: AdCopyStrategyGroup): boolean {
	return isDefined(strategyTargetGroup.aestheticMedicineExperience);
}

export const checkInputScript = (quename: string, withoutWait = false) => {
	return `
	<script type="text/javascript">
		$(function(){
			$.getScript("/public/testit-library/build/ad-copy/input-limit.js", function(){
				inputLimit("${quename}", { minLength:5, maxLength:400 ${withoutWait ? "" : `,waitSecond:10`}});
			});
		});
	</script>
	`;
};

export const getMatrixStyle = (quename: string) => {
	return `
<style type="text/css">
	table#${quename} .names_area{
		width:300px;
	}
	@media screen and (max-width:600px) {
		table#${quename} .names_area{
			width:220px;
		}
	}
	@media screen and (max-width:460px) {
		table#${quename} .names_area{
			width:180px;
		}
	}
</style>
<script type="text/javascript">
	$(function(){
		$.getScript("/public/testit-library/build/ad-copy/matrix.js", function(){
			matrixDisplayByTargetCount("${quename}", 4);
		});
	});
</script>`;
};

export const waitScript = (waitSecond = 10) => {
	return `
	<script type="text/javascript">
		$(function(){
			$.getScript("/public/testit-library/build/ad-copy/wait-next.js", function(){
				waitNext(${waitSecond});
			});
		});
	</script>
	`;
};

export const hasTargetBrands = (targetCategoryBrandValues?: number[]) => {
	return isNotEmptyArray(targetCategoryBrandValues);
};

export const makeConceptChoiceConditions = (
	conceptNumber: number,
	generalGroupQuota: AdCopyGeneralGroupQuota[],
): string => {
	const ageAndQuotaConditions = getCategoryGeneralGroupUserQuotas(generalGroupQuota).flatMap(({ age }, index) => {
		const ageCondition = makeAgeCondition(age);
		const quotaCode = makeCategoryUserQuotaCode(conceptNumber, index);

		return `(${ageCondition} and Quota[${quotaCode}]!="COMP")`;
	});

	return `${isCategoryUserAdCopyQuename}=${adCopyFlagCommonValue} and (${ageAndQuotaConditions.join(" or ")})`;
};
