import type { AxiosResponse } from "axios";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type {
	UpsertAdCopyActivityRequest,
	UpsertAdCopyActivityResponse,
} from "../../../../../../server/types/request/ad-copy/activity";
import { AdCopyActivityCreatePage } from "../../../../components/pages/ad-copy/activity/create";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { Prompt } from "../../../../components/parts/prompt";
import { get, makeError, post, put } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/activity/create";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";
import type {
	GetTrueAdCopyActivityResponse,
	TrueAdCopySimpleActivity,
	TrueAdCopyStrategyGroup,
} from "../../../../types/ad-copy/activity";

export const AdCopyActivityCreateContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { accounts, activity, brands, cantEditMessage, categories, info, loading, prompt, strategyTargetGroup } = state;

	const onChangeActivity = React.useCallback(
		<T extends keyof TrueAdCopySimpleActivity>(name: T, value: TrueAdCopySimpleActivity[T]) => {
			dispatch({ payload: { name, value }, type: "changeActivity" });
		},
		[],
	);

	const onChangeStrategyGroup = React.useCallback(
		<T extends keyof TrueAdCopyStrategyGroup>(name: T, value: TrueAdCopyStrategyGroup[T]) => {
			dispatch({ payload: { name, value }, type: "changeStrategyGroup" });
		},
		[],
	);

	const { activityId } = useParams<{ activityId: string }>();

	React.useEffect(() => {
		get<GetTrueAdCopyActivityResponse>(`${adCopyEndpoint.activity}/${activityId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadActivity" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [activityId]);

	const onSubmit = React.useCallback(() => {
		dispatch({ payload: true, type: "changeLoading" });

		// 作成後のURLもnewのままだが、activityがsetされると_idがつくので、参照するようにする。
		const id = activity?._id ? activity._id.toString() : activityId;

		const data = {
			...activity,
			strategyTargetGroup,
		} as UpsertAdCopyActivityRequest;

		const request: Promise<AxiosResponse<UpsertAdCopyActivityResponse>> =
			id === "new" ? post(adCopyEndpoint.activity, data) : put(`${adCopyEndpoint.activity}/${id}`, data);

		request
			.then((response) => {
				dispatch({ payload: response.data.activity, type: "updateActivity" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [activity, activityId, strategyTargetGroup]);

	const navigate = useNavigate();

	const onClose = React.useCallback(() => {
		navigate(adCopyClientEndpoint.activity);
	}, [navigate]);

	return (
		<>
			<Prompt message="変更が保存されていません。ページを移動してよろしいですか？" when={prompt && !loading} />

			<Loading loading={loading} text="processing..." />

			<ConfirmBox info={info} onClose={onClose} />

			{activity && (
				<AdCopyActivityCreatePage
					accounts={accounts}
					activity={activity}
					brands={brands}
					cantEditMessage={cantEditMessage}
					categories={categories}
					errors={info.errors}
					loginAccount={loginAccount}
					onChangeActivity={onChangeActivity}
					onChangeStrategyGroup={onChangeStrategyGroup}
					onSubmit={onSubmit}
					strategyTargetGroup={strategyTargetGroup}
				/>
			)}
		</>
	);
};
