/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Col, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import styled from "styled-components";

import type { ErrorObject } from "../../../../../../../../server/types/error";
import { getErrorMessage } from "../../../../../../lib/error";
import type { TrueAdCopySku } from "../../../../../../types/ad-copy/concept";

const TaxIncludedArea = styled.div`
	display: inline-block;
	width: 85%;
	padding: 0.375rem 0.75rem;
	border: 1px solid #ddd;
	background-color: #eee;
	border-radius: 0.25rem;
`;

type Props = {
	disabled?: boolean;
	errors?: ErrorObject;
	onChange: <T extends keyof TrueAdCopySku>(name: T, value: TrueAdCopySku[T]) => void;
	setTaxIncluded: () => void;
	sku: TrueAdCopySku;
};

export const AdCopySkuForm = React.memo((props: Props) => {
	const { disabled, errors, onChange, setTaxIncluded, sku } = props;

	const onBlurPrice = React.useCallback(() => {
		if (sku.price) {
			setTaxIncluded();
		}
	}, [setTaxIncluded, sku]);

	const onChangePrice = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const tmp = Number(e.target.value);

			if (isNaN(tmp)) {
				return alert("半角数値で入力してください。");
			}
			onChange("price", tmp);
		},
		[onChange],
	);

	return (
		<Row>
			<Col md={4}>
				<FormGroup>
					<FormLabel>SKU説明（容量必須）</FormLabel>

					<FormControl
						disabled={disabled}
						isInvalid={!!errors && !!errors["name"]}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("name", e.target.value)}
						value={sku.name || ""}
					/>

					{!!errors && !!errors["name"] && <Feedback type="invalid">{getErrorMessage("name", errors)}</Feedback>}
				</FormGroup>
			</Col>

			<Col md={4}>
				<FormGroup>
					<FormLabel>価格（税抜金額）</FormLabel>

					<div>
						<FormControl
							disabled={disabled}
							isInvalid={!!errors && !!errors["price"]}
							onBlur={onBlurPrice}
							onChange={onChangePrice}
							style={{ display: "inline", width: "85%" }}
							value={sku.price === undefined ? "" : sku.price.toString()}
						/>
						円
					</div>

					{!!errors && !!errors["price"] && (
						<Feedback style={{ display: "block" }} type="invalid">
							{getErrorMessage("price", errors)}
						</Feedback>
					)}
				</FormGroup>
			</Col>

			<Col md={4}>
				<FormGroup>
					<FormLabel>価格（税込金額:消費税10%）</FormLabel>
					<TaxIncludedArea>{sku.includeTaxPrice || "　"}</TaxIncludedArea>円
					{!!errors && !!errors["includeTaxPrice"] && (
						<Feedback style={{ display: "block" }} type="invalid">
							{getErrorMessage("includeTaxPrice", errors)}
						</Feedback>
					)}
				</FormGroup>
			</Col>
		</Row>
	);
});
