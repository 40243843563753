import * as React from "react";
import { Button, Col, FormControl, FormLabel, Row } from "react-bootstrap";
import styled from "styled-components";

import { adCopyConceptOptions } from "../../../../../../../server/models/ad-copy/concept";
import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import type {
	AdCopyOAData,
	AdCopyOADataName,
	AdCopyOATableData,
} from "../../../../../../../server/models/ad-copy/output";
import { adCopyTargetGroupOptions } from "../../../../../../../server/models/ad-copy/output";
import { array2CsvBlob, downloadData } from "../../../../../lib/data";
import { AdCopyMultipleSelect } from "../../../../parts/ad-copy/select/multiple-select";
import { AdCopySingleSelect } from "../../../../parts/ad-copy/select/single-select";
import { AdCopyReportBigTitle } from "../parts/report-title";
import { AdCopyOASearchTableRows } from "./rows";
import { AdCopySubTitle } from "./sub-title";

const AdCopySelectArea = styled.div`
	width: 100%;
`;

const AdCopyOATable = styled.table`
	width: 100%;
	font-size: 0.9em;
	thead {
		vertical-align: top;
	}
	.form-control {
		height: 27px;
		padding: 0px;
	}
	tr.button-area td {
		text-align: right;
		padding-right: 10px;
		padding-bottom: 10px;
	}
	tr.head-section td {
		border-bottom: 1px solid #999;
		padding: 2px;
	}
	tr.head-section td {
		text-align: center;
		background-color: #ddd;
	}
	div[class$="-ValueContainer"],
	div[class$="-IndicatorsContainer"],
	div[class$="-indicatorContainer"],
	div.css-1hwfws3 {
		padding: 0px !important;
	}
	div[class$="-control"] {
		min-height: 27px;
	}
	td.data-num,
	td.data-age,
	td.data-ageGroup {
		text-align: center;
	}
	td.num {
		min-width: 70px;
	}
	td.age {
		min-width: 40px;
	}
	td.ageGroup {
		min-width: 138px;
	}
	td.purchaseIntent,
	td.differentiation,
	td.forSelf,
	td.informationSearchIntent,
	td.catchCopyImpression,
	td.catchCopyAttractiveness {
		min-width: 160px;
	}
	td[class*="S14-"] {
		min-width: 300px;
		max-width: 350px;
	}
	td.impressionBeforePrice,
	td.dislikePoints {
		min-width: 400px;
	}
	tr.data-section td {
		border-bottom: 1px dotted #ddd;
	}
	td.search-label {
		vertical-align: middle;
	}
`;

type Props = {
	data: AdCopyOATableData;
	job: AdCopyJob;
};

export const AdCopyOASearchTable = React.memo((props: Props) => {
	const { data, job } = props;

	const { oaColumns, oaData } = data;

	const [filterValues, setFilterValues] = React.useState<AdCopyOAData>({
		conceptType: "test1",
		targetGroups: "strategyTarget",
	});

	const filename = React.useMemo(() => (job ? `${job.jobNum}-oa` : "oa"), [job]);

	const choiceMap = React.useMemo<Partial<Record<AdCopyOADataName, Map<number | string | undefined, string>>>>(
		() =>
			oaColumns
				.filter((column) => column.type === "select")
				.reduce(
					(a, b) => ({
						...a,
						[b.name]: new Map(b.options?.map(({ label, value }) => [value, label])),
					}),
					{},
				),
		[oaColumns],
	);

	const filteredData = React.useMemo(
		() =>
			oaData.filter((oaDataRow) => {
				if (filterValues.conceptType && filterValues.conceptType !== oaDataRow.conceptType) {
					return false;
				}

				if (filterValues.targetGroups && !(oaDataRow.targetGroups as any[]).includes(filterValues.targetGroups)) {
					return false;
				}

				return oaColumns.every((column) => {
					const filterValue = filterValues[column.name];

					if (!filterValue) {
						return true;
					}

					const cellValue = oaDataRow[column.name];

					if (column.type === "text") {
						return cellValue?.toString().includes(filterValue.toString());
					}

					if (Array.isArray(cellValue)) {
						if (!cellValue.length) {
							return false;
						}

						return (filterValue as any[]).some((v) => (cellValue as any[]).includes(v));
					}

					return (filterValue as any[]).includes(cellValue);
				});
			}),
		[oaData, filterValues, oaColumns],
	);

	const onChangeFilter = React.useCallback(
		(name: AdCopyOADataName, value: any) => {
			const tmp = { ...filterValues };

			if (!value || (Array.isArray(value) && value.length === 0)) {
				delete tmp[name];
			} else {
				tmp[name] = value;
			}

			setFilterValues(tmp);
		},
		[filterValues],
	);

	const onDownload = React.useCallback(() => {
		const data = filteredData.map((data) =>
			oaColumns.map((column) => {
				const tmp = data[column.name];

				if (Array.isArray(tmp)) {
					if (column.type !== "select") {
						return tmp.join(",");
					}

					const choice = choiceMap[column.name];

					return tmp.map((v) => choice?.get(v)).join(",");
				}

				if (column.type === "select") {
					const choice = choiceMap[column.name];

					return choice?.get(tmp) || tmp;
				}

				return tmp || "";
			}),
		);

		downloadData(array2CsvBlob([oaColumns.map((column) => column.label), ...data]), `${filename}.csv`);
	}, [oaColumns, filteredData, choiceMap, filename]);

	const trSearchFilter = React.useMemo(() => {
		const { forms, labels } = oaColumns.reduce<{ forms: React.ReactNode[]; labels: React.ReactNode[] }>(
			(a, column) => {
				a.labels.push(
					<td key={`search-${column.name}-label`} className={[column.name, "search-label"].join(" ")}>
						{column.label}
					</td>,
				);

				a.forms.push(
					<td key={`search-${column.name}`} className={column.name}>
						{column.type === "text" && (
							<FormControl
								name={column.name}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									onChangeFilter(column.name, e.target.value);
								}}
								value={filterValues[column.name] ? filterValues[column.name]?.toString() : ""}
							/>
						)}

						{column.type === "select" && (
							<AdCopySelectArea>
								<AdCopyMultipleSelect
									onChange={(values) => {
										onChangeFilter(column.name, values);
									}}
									options={column.options ?? []}
									value={(filterValues[column.name] as any) || []}
								/>
							</AdCopySelectArea>
						)}
					</td>,
				);

				return a;
			},
			{ forms: [], labels: [] },
		);

		return (
			<>
				<tr>{labels}</tr>

				<tr>{forms}</tr>
			</>
		);
	}, [oaColumns, filterValues, onChangeFilter]);

	return (
		<>
			<AdCopyReportBigTitle>コンセプトの印象　自由回答集（{job.jobNum}）</AdCopyReportBigTitle>

			<Row>
				<Col md={3}>
					<FormLabel>グループ</FormLabel>

					<AdCopySingleSelect
						onChange={(value) => {
							onChangeFilter("targetGroups", value);
						}}
						options={adCopyTargetGroupOptions}
						value={filterValues.targetGroups}
					/>
				</Col>

				<Col md={3}>
					<FormLabel>コピー・訴求</FormLabel>

					<AdCopySingleSelect
						onChange={(value) => {
							onChangeFilter("conceptType", value);
						}}
						options={adCopyConceptOptions}
						value={filterValues["conceptType"]}
					/>
				</Col>
			</Row>

			<AdCopySubTitle>フィルター</AdCopySubTitle>

			<AdCopyOATable>
				<thead>
					{trSearchFilter}

					<tr>
						<th colSpan={oaColumns.length}>
							<AdCopySubTitle>自由回答集</AdCopySubTitle>
						</th>
					</tr>
				</thead>

				<tbody>
					<tr className="button-area">
						<td colSpan={oaColumns.length}>
							<Button onClick={onDownload} size="sm" variant="outline-secondary">
								Download
							</Button>
						</td>
					</tr>

					<tr className="head-section">
						{oaColumns.map((column) => (
							<td key={`head-${column.name}`}>{column.label}</td>
						))}
					</tr>

					<AdCopyOASearchTableRows
						choiceMap={choiceMap}
						filteredData={React.useDeferredValue(filteredData)}
						oaColumns={oaColumns}
					/>
				</tbody>
			</AdCopyOATable>
		</>
	);
});
