import { MatrixChildQuestion, Choice } from "@muscat/types";
import { AdCopyChildren } from "../../../models/ad-copy/activity";

import { Option } from "../../../types/request";
import { AdCopyConceptKeyForQuota } from "../../../models/ad-copy/concept";
import { AdCopyTargetGroup } from "../../../models/ad-copy/enquete";

/**
 * gmoDeliverConfig
 */
export const adCopyGmoDeliverConfigBaseTag = "testit_adcopy";

/**
 * - 20240618 gmo
 * - screening: 属性5問は0問カウント、それ以外は1問カウント
 * - main: 設問種別、設問内容（選択肢数等）に関わらず、本調査は1問1カウントで換算する
 */
const baseQuestionQueCount = 1;
export const screeningQuestionQueCount = baseQuestionQueCount;
export const mainQuestionQueCount = baseQuestionQueCount;

export const generalMinAgeAdCopy = 15;
export const generalMaxAgeAdCopy = 69;
export const genderAdCopyQuename = "S3";
export const ageAdCopyQuename = "S4";
export const marriedAdCopyQuename = "S5";
export const areaAdCopyQuename = "S6";
export const familyAdCopyQuename = "S7";
export const occupationAdCopyQuename = "S8";
export const skinAttributeAdCopyQuename = "S9";
export const sensitiveSkinAdCopyQuename = "S10";
export const sensitiveSkinFrequencyAdCopyQuename = "SQ0";
export const skinProblemFrequencyAdCopyQuename = "S11";
export const categoryAdCopyQuename = "S12";
export const facewashFunctionAdCopyQuename = "SQ1";
export const foundationTypeAdCopyQuename = "SQ2";
export const lipstickTypeAdCopyQuename = "SQ3";
export const eyeshadowTypeAdCopyQuename = "SQ4";
export const priceAdCopyQuename = "S13";
export const channelAdCopyQuename = "S14";
export const usedBrandAdCopyQuePrefix = "S15";
export const currentlyUseBrandAdCopyQuePrefix = "S16";
export const beautyTypeAdCopyQuename = "S17";
export const gypsyAdCopyQuename = "S18";
export const aestheticMedicineExperienceAdCopyQuename = "S19";

export const isCategoryUserAdCopyQuename = "A1";
export const isStrategyTargetAdCopyQuename = "A2";
export const categoryUserConceptAdCopyQuename = "A3";
export const strategyConceptAdCopyQuename = "A4";
export const isBrandUserAdCopyQuename = "A5";

/** 戦略ターゲットorブランドユーザー */
export const isStrategyOrBrandUserAdCopyQuename = "A6";

export const conceptAdCopyQuename = "C1";
export const competitorImageAdCopyQuename = "C2";
export const skuPriceAdCopyQuename = "C3";

export const isBoostAdCopyQuename = "A7";

export const adCopyGroupOrderQuename = "A8";

export const transitFaceAdCopyQuename = "E1";

export const impressionBeforePriceAdCopyQuename = "Q1";
export const useIntentBeforePriceAdCopyQuename = "Q2";
export const purchaseIntentAdCopyQuename = "Q3";
/** 購入意向　他者予測 （テスト品のみ）*/
export const predictOfOthersAdCopyQuename = "Q4";
/** 目を引く程度 */
export const kvAttractionQuename = "Q5";
/** ブランドリンケージ */
export const kvBrandLinkageQuename = "Q6";
/** 項目別評価 */
export const evaluationAdCopyQuename = "Q7";

/** キャッチコピー印象度 */
export const catchCopyImpressionQuePrefix = "Q8";
/** キャッチコピー魅力度 */
export const catchCopyAttractivenessQuePrefix = "Q9";

/** メインコピーの子設問 */
export const mainCopyChildQuename = "s1";

/** キービジュアル好意度 */
export const kvFavorabilityQuename = "Q10";
/** 気に入らなかった点 */
export const dislikePointsQuename = "Q11";

/** 以下競合品 */
export const transitFace2AdCopyQuename = "E2";
export const competitorImpressionBeforePriceAdCopyQuename = "Q12";
export const competitorUseIntentBeforePriceAdCopyQuename = "Q13";
export const competitorPurchaseIntentAdCopyQuename = "Q14";

/** 目を引く程度 */
export const competitorKVAttractionQuename = "Q15";
/** ブランドリンケージ */
export const competitorKVBrandLinkageQuename = "Q16";
/** 項目別評価 */
export const competitorEvaluationAdCopyQuename = "Q17";
/** キャッチコピー印象度 */
export const competitorCatchCopyImpressionQuename = "Q18";
/** キャッチコピー魅力度 */
export const competitorCatchCopyAttractivenessQuename = "Q19";
/** キービジュアル好意度 */
export const competitorKVFavorabilityQuename = "Q20";
/** 気に入らなかった点 */
export const competitorDislikePointsQuename = "Q21";

export const lastFaceAdCopyQuename = "E3";

/** market maven */
export const marketMavenQuename = "Q23";

// 最大回収は60000だが、足が出るとその分rangeがあがるので-1000する。
export const adCopyMaxAnsweredLimit = 60000;
export const adCopyMaxAnswered = adCopyMaxAnsweredLimit - 1000;

export const adCopySampleSizes = {
	strategyTarget: 100,
	categoryUser: 200,
	brandUser: 30,
} as const satisfies { [key in AdCopyTargetGroup]: number };

/**
 * 戦略ターゲット、カテゴリ、ブランドユーザー、marketMavenなどflag共通の値
 */
export const adCopyFlagCommonValue = 1;

export const familyChoices: Choice[] = [
	{
		value: 1,
		text: "配偶者（夫・妻）",
		displayCondition: `${marriedAdCopyQuename}= 2`,
	},
	{ value: 2, text: AdCopyChildren.preschool },
	{ value: 3, text: AdCopyChildren.elementarySchool },
	{ value: 4, text: AdCopyChildren.juniorHighSchool },
	{ value: 5, text: AdCopyChildren.highSchool },
	{ value: 6, text: AdCopyChildren.university },
	{ value: 7, text: "父親（義父含む）" },
	{ value: 8, text: "母親（義母含む）" },
	{ value: 9, text: "その他の家族（兄弟・姉妹、祖父母、孫など）" },
	{ value: 10, text: "その他（友人、恋人・パートナーなど）" },
	{ value: 11, text: "ひとり暮らし（同居している人はいない）", exclusive: true },
];

export const choice2Option = (choices: Choice[]): Option[] => {
	return choices.map((choice) => {
		return {
			label: choice.text,
			value: choice.value,
		};
	});
};

export const array2Option = (arr: string[]): Option[] => {
	return arr.map((str) => {
		return {
			label: str,
			value: str,
		};
	});
};

// コンセプトは順番を決めておく。上からtest1、test2、test3, 現行品, (競合品セルないため、なし)
export const adCopyConceptNumberMap = {
	test1: 1,
	test2: 2,
	test3: 3,
	currentProduct: 4,
} as const satisfies { [key in AdCopyConceptKeyForQuota]: number };

export const adCopyConceptTypeNumberMap: { [key: number]: AdCopyConceptKeyForQuota } = {
	[adCopyConceptNumberMap.test1]: "test1",
	[adCopyConceptNumberMap.test2]: "test2",
	[adCopyConceptNumberMap.test3]: "test3",
	[adCopyConceptNumberMap.currentProduct]: "currentProduct",
} as const;

export const adCopyQuotaCodeMap = {
	categoryUser: "C",
	strategy: "S",
	brandUser: "B",
} as const;

/**
 * カテゴリユーザーでブースト対象でない場合、
 *  自社テスト品セルは、本調査で他社予測 Q4 までのみ回答
 *  現行品セルは購入意向設問 Q3 までのみ回答
 * !! 調査表設計変更した場合、ここも変更する必要がある
 */
export const mainQuestionCountForPureREP = {
	test: 4,
	currentProduct: 3,
};

export const adCopyOccupationChoices: Choice[] = [
	{ value: 1, text: "会社役員・会社員・公務員" },
	{ value: 2, text: "派遣社員" },
	{ value: 3, text: "パート・アルバイト" },
	{ value: 4, text: "自営業" },
	{ value: 5, text: "自由業・フリーランス" },
	{ value: 6, text: "高校生・高専生" },
	{ value: 7, text: "大学（短大・専門学校）生・大学院生" },
	{ value: 8, text: "専業主婦・主夫" },
	{ value: 9, text: "無職（定年後退職を含む）・家事手伝い" },
	{ value: 10, text: "その他", other: {} },
];

export const adCopyMarriedChoices: Choice[] = [
	{ value: 1, text: "未婚" },
	{ value: 2, text: "既婚" },
	{ value: 3, text: "離別・死別" },
];

export const adCopyPurchaseIntentChoices: Choice[] = [
	{ value: 1, text: "非常に買いたい" },
	{ value: 2, text: "買いたい" },
	{ value: 3, text: "どちらともいえない" },
	{ value: 4, text: "買いたくない" },
	{ value: 5, text: "まったく買いたくない" },
];

export const adCopyEvaluationChoices: Choice[] = [
	{ value: 1, text: "非常にそう思う" },
	{ value: 2, text: "そう思う" },
	{ value: 3, text: "どちらともいえない" },
	{ value: 4, text: "そう思わない" },
	{ value: 5, text: "まったくそう思わない" },
];

export const catchCopyImpressionChoices: Choice[] = [
	{
		text: "非常に印象に残る",
		value: 1,
	},
	{
		text: "印象に残る",
		value: 2,
	},
	{
		text: "どちらともいえない",
		value: 3,
	},
	{
		text: "印象に残らない",
		value: 4,
	},
	{
		text: "まったく印象に残らない",
		value: 5,
	},
];

export const catchCopyAttractivenessChoices: Choice[] = [
	{
		text: "非常に魅力を感じる",
		value: 1,
	},
	{
		text: "魅力を感じる",
		value: 2,
	},
	{
		text: "どちらともいえない",
		value: 3,
	},
	{
		text: "魅力を感じない",
		value: 4,
	},
	{
		text: "まったく魅力を感じない",
		value: 5,
	},
];

/** 差別性子設問 */
export const differentiationChildQuestion = {
	quename: "s2",
	quetitle: "他の商品とは違っている",
	quelabel: "差別性",
} as const satisfies Omit<MatrixChildQuestion, "type">;

/** 情報検索意向子設問 */
export const informationSearchIntentChildQuestion = {
	quename: "s13",
	quetitle: "この商品についてもっと知りたい",
	quelabel: "情報検索意向",
} as const satisfies Omit<MatrixChildQuestion, "type">;

/** 自分向け子設問 */
export const forSelfChildQuestion = {
	quename: "s3",
	quetitle: "自分向けの商品だと思う",
	quelabel: "自分向け",
} as const satisfies Omit<MatrixChildQuestion, "type">;

export const adCopyEvaluationItemMatrixChildQuestion: MatrixChildQuestion[] = [
	{ quename: "s1", quetitle: "新しさを感じる", type: "S" },
	{
		...differentiationChildQuestion,
		type: "S",
	},
	{ ...forSelfChildQuestion, type: "S" },
	{ quename: "s4", quetitle: "効果が期待できそう", type: "S" },
	{ quename: "s5", quetitle: "自分の魅力を高めてくれそう", type: "S" },
	{ quename: "s6", quetitle: "自分の悩みや不安を解消してくれそう", type: "S" },
	{ quename: "s7", quetitle: "使うと気分が上がりそう", type: "S" },
	{ quename: "s8", quetitle: "わかりやすい", type: "S" },
	{ quename: "s9", quetitle: "このブランドの他の商品も使ってみたくなる", type: "S" },
	{ quename: "s10", quetitle: "信じられる", type: "S" },
	{ quename: "s11", quetitle: "価格が高すぎる", type: "S" },
	{ quename: "s12", quetitle: "買い求めやすい", type: "S" },
	{
		...informationSearchIntentChildQuestion,
		type: "S",
	},
];

export const ageOptions = ["15〜19歳", "20〜29歳", "30〜39歳", "40〜49歳", "50〜59歳", "60〜69歳", "70〜79歳"];
export const adCopyPurchaseIntentOptions = adCopyPurchaseIntentChoices.map((pc) => pc.text);
export const adCopyEvaluationOptions = adCopyEvaluationChoices.map((ec) => ec.text);
