import * as React from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";

// import { replaceBreakAndSpace } from "../../../../../../../../server/lib/common";

type Props = {
	disabled?: boolean;
	error: string | undefined;
	label: React.ReactNode;
	onChange: (value: string) => void;
	value: string;
};

export const AdCopyCountableTextarea = React.memo((props: Props) => {
	const { disabled, error, label, onChange, value } = props;

	// const length = React.useMemo(() => replaceBreakAndSpace(value).length, [value]);

	return (
		<>
			<Row>
				<Col>{label}</Col>
			</Row>

			<Row>
				<Col md={8}>
					<FormControl
						as="textarea"
						disabled={disabled}
						onChange={(e) => {
							onChange(e.target.value);
						}}
						style={{ fontWeight: "bold", margin: "10px 0px" }}
						value={value}
					/>

					{error && (
						<Feedback style={{ display: "block", whiteSpace: "pre-wrap" }} type="invalid">
							{error}
						</Feedback>
					)}
				</Col>

				{/* <Col md={1}>{length}</Col> */}
			</Row>
		</>
	);
});
