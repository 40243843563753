import { ObjectId } from "mongodb";
import { AdCopyJobStatus, AdCopySurveyStatus } from "./status";

export enum AdCopySurveyMethod {
	"copy-appeal" = "コピー・訴求調査",
}
export const adCopySurveyMethodOptions = Object.entries(AdCopySurveyMethod)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

/** 自社テスト品は最大３品 */
export type AdCopyNumOfTests = 1 | 2 | 3;

export type AdCopyJob = {
	_id?: ObjectId | string; //ジョブID
	activityId: ObjectId | string;
	/** 20JPTC0001のような形になる。*/
	jobNum: string;
	accountId: ObjectId | string; //作成者
	startDatetime: string; // 開始日
	endDatetime: string; // 終了日
	numOfTests: AdCopyNumOfTests; // テスト品の数
	hasCurrentProduct: boolean; // 現行品の有無
	hasBenchmark: boolean; // 競合品の有無
	benchmarkName?: string; // 競合品製品名
	hasKeyVisual: boolean; // キービジュアルの有無
	retestJobId?: string | ObjectId; // 再テスト調査
	status: keyof typeof AdCopyJobStatus;
	method: keyof typeof AdCopySurveyMethod; // 調査手法
	enqueteId: string; //muscatのアンケートID
	createdAt?: string;
	updatedAt?: string;
	inspection?: AdCopyInspection;
};

export type AdCopyInspection = {
	accountId: ObjectId | string; //承認者
	inspectDatetime: string;
};

export type AdCopyJobWithSurveyStatus = AdCopyJob & {
	surveyStatus: keyof typeof AdCopySurveyStatus; // 表示されるジョブのステータス。
};
