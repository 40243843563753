/* eslint-disable @typescript-eslint/naming-convention */
import { ObjectId } from "mongodb";
import { ActivityStatus } from "./status";
import { Category } from "./category";
import { Brand } from "./brand";

export enum SalesScale {
	moreThanBillion = "10億円以上",
	moreThan300million = "3～10億円未満",
	lessThan300million = "3億円未満",
	undecided = "未定",
}
export const salesScaleOptions = Object.entries(SalesScale)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum Grp {
	moreThan1000 = "1000GRP以上",
	lessThan1000 = "1000GRP未満",
	none = "なし",
	undecided = "未定",
}
export const grpOptions = Object.entries(Grp)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum Gender {
	male = "男性",
	female = "女性",
}
export const genderOptions = Object.entries(Gender)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export interface Age {
	from: number;
	to: number;
}
export interface Price {
	from?: number;
	to?: number;
	label?: string;
}
// categoryはactivityでもつので不要とする。
export interface CategoryGroup {
	price?: Price; //labelは選択肢表示用（人口分布差し替え時に、旧選択肢を表示するために使用）
	age: Age;
	appearance: number; //出現率
	// ユーザーかノンユーザーかはシステムで使用しないのでDBに登録しない
}

export enum Married {
	unmarried = "未婚（離死別も含む）",
	married = "既婚",
}
export const marriedOptions = Object.entries(Married)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum Children {
	preschool = "子ども（未就学児/0歳～6歳）",
	elementarySchool = "子ども（小学生/6歳～12歳）",
	juniorHighSchool = "子ども（中学生/12歳～15歳",
	highSchool = "子ども（高校生/15歳～18歳）",
	university = "子ども（大学生以上/18歳以上）",
}
export const childrenOptions = Object.entries(Children)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum Occupation {
	employed = "有職（パート・アルバイト含む）",
	notEmployed = "無職",
	student = "学生",
}
export const occupationOptions = Object.entries(Occupation)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum SkinAttribute {
	"シミ・ソバカス",
	"くすみ",
	"透明感のなさ",
	"頬の赤み",
	"色むら",
	"目の下のくま",
	"しわ",
	"肌のたるみ",
	"毛穴の目立ち",
	"かさつき・うるおいのなさ",
	"つやのなさ",
	"きめの粗さ",
	"肌荒れ",
	"ニキビ・吹き出物",
	"脂っぽさ・べたつき",
	"アトピー/肌のアレルギー",
	"花粉 / PM2.5 / ハウスダストなどによる肌荒れ",
	/*/
	"シミ・ソバカス",
	"毛穴の目立ち",
	"肌のたるみ",
	"かさつき・うるおいのなさ",
	"ニキビ・吹き出物",
	"くすみ",
	"透明感のなさ",
	"しわ",
	"目の下のくま",
	"肌荒れ",
	"脂っぽさ・べたつき",
	"きめの粗さ",
	"つやのなさ",
	"頬の赤み",
	"色むら",
	"アトピー/肌のアレルギー",
	"花粉/PM2.5/ハウスダストなどによる肌荒れ",
	/*/
	"その他",
}
export const skinAttributeOptions = Object.entries(SkinAttribute)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export const skinAttributeExcludeOtherOptions = skinAttributeOptions.filter(({ label }) => {
	return label !== "その他";
});

export enum SensitiveSkin {
	敏感肌,
	ときどき敏感肌,
	敏感肌ではない,
}

export const sensitiveSkinOptions = Object.entries(SensitiveSkin)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum CategoryOrAnd {
	Or = "カテゴリ間の条件をorで設定する",
	And = "カテゴリ間の条件をandで設定する",
}
export const categoryOrAndOptions = Object.entries(CategoryOrAnd)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum Channel {
	"化粧品専門店（駅ビルや商業施設・地下街などのお店、町の路面店など）",
	"デパート・百貨店",
	"総合スーパー（イオン、イトーヨーカドー、ダイエーなど）",
	"スーパーマーケット",
	"ドラッグストア・薬局・薬店",
	"ホームセンター・ディスカウントストア",
	"コンビニエンスストア",
	"バラエティストア（PLAZA、ショップイン、東急ハンズ、Loftなど）",
	"ブランド直営店（ザ・ボディショップ、ロクシタン、ファンケル、DHC、オルビスなど）",
	"美容院",
	"訪問販売",
	"インターネット通販",
	"通信販売（インターネットを除く）",
	"エステサロン",
	"皮膚科／美容クリニック",
	"その他",
}

export const channelOptions = Object.entries(Channel)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum BeautyType {
	"保湿",
	"美白",
	"エイジングケア",
	"ニキビ・アクネケア",
	"敏感肌対策",
	"UV効果（SPFやPA付き）",
	"その他",
}
export const beautyTypeOptions = Object.entries(BeautyType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum Fragrance {
	"香り無し",
	"香り付き",
}
export const fragranceOptions = Object.entries(Fragrance)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum FoundationType {
	"リキッド　タイプ",
	"クッション　タイプ",
	"クリーム　タイプ",
	"エマルジョン　タイプ",
	"その他",
}

export const foundationTypeOptions = Object.entries(FoundationType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum FacewashFunction {
	"洗顔機能のみの商品",
	"メイク落とし機能のみの商品",
	"洗顔～メイク落としまでできる商品（ダブル洗顔不要）",
	"その他",
}
export const facewashFunctionOptions = Object.entries(FacewashFunction)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export type StrategyGroupCategory = {
	category: Category;
	surveyChoice: number;
	price?: Price;
	beautyType?: (keyof typeof BeautyType)[];
	usedBrand?: string[]; // brandName
	unusedBrand?: string[]; // brandName
};

export type StrategyGroup = {
	age: Age;
	married: (keyof typeof Married)[]; //未既婚
	children: (keyof typeof Children)[]; //子ども有無
	occupation: (keyof typeof Occupation)[]; //職業
	skinAttribute: (keyof typeof SkinAttribute)[]; //肌悩み
	sensitiveSkin: (keyof typeof SensitiveSkin)[]; //敏感肌
	categoryOrAnd: keyof typeof CategoryOrAnd;
	category: StrategyGroupCategory[];
	channel: (keyof typeof Channel)[];
	fragrance?: (keyof typeof Fragrance)[];
	foundationType?: (keyof typeof FoundationType)[];
	facewashFunction?: keyof typeof FacewashFunction;
};

export enum ProductType {
	newItem = "新商品",
	renewal1 = "リニューアル（外装強化＆中味強化）",
	renewal2 = "リニューアル（外装強化のみ）",
	existingProductAdd = "既存品の色調/香調/容量等追加",
	existingProductOnlyComminication = "既存品のコミュニケーションのみ開発",
	other = "その他",
}

export const noSettingStrategyGroupReason = {
	noCorrespondingItem: "Testitに対応する項目がない",
	sameAsCategoryTargetGroup: "カテゴリターゲットグループと同じでよい（※提案時に説明必須）",
	other: "その他",
} as const;

export type NoSettingStrategyGroupReason = keyof typeof noSettingStrategyGroupReason;

export const noSettingStrategyGroupReasonKeys = Object.keys(
	noSettingStrategyGroupReason,
) as NoSettingStrategyGroupReason[];

export type NoSettingStrategyGroup = {
	reasons: NoSettingStrategyGroupReason[];
	specifically?: string;
};

export const productTypeOptions = Object.entries(ProductType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export type SimpleActivity = {
	_id?: ObjectId | string;
	no?: number;
	accountIds: (string | ObjectId)[];
	brandId: ObjectId | string;
	status: keyof typeof ActivityStatus;
	releaseYear: number;
	releaseMonth: number;
	salesScale: keyof typeof SalesScale;
	grp: keyof typeof Grp;
	goalRecognitionRate: number;
	goalSalesVolume: number;
	category: Category;
	productType: keyof typeof ProductType;
	memo?: string;
	createdAt?: string;
	updatedAt?: string;
};

export type Activity = SimpleActivity & {
	categoryTargetGroup?: CategoryGroup;
	strategyTargetGroup?: StrategyGroup;
	noSettingStrategyGroup?: NoSettingStrategyGroup;
};

export type ActivityWithBrand = Activity & {
	brand: Brand;
};

export const borderAppearance = 3;
