/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyGroupCategoryLabels } from "../../../../../../../../label/ad-copy/activity";
import type { AdCopyStrategyGroupCategory } from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopyCategory } from "../../../../../../../../server/models/ad-copy/category";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";
import { AdCopyBrandSelect } from "./brand-select";

type Props = {
	categoryMap: Record<string, AdCopyCategory>;
	onChangeStrategyGroupCategory: (surveyChoice: number, name: keyof AdCopyStrategyGroupCategory, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
	surveyChoiceMap: Map<number, string>;
};

/**
 * 過去1年購入＆使用ブランド
 */
export const AdCopyUsedBrandForms = React.memo((props: Props) => {
	const { categoryMap, onChangeStrategyGroupCategory, strategyGroup, surveyChoiceMap } = props;

	return (
		<Row>
			<FormGroup as={Col}>
				<AccordionForm
					defaultShow={strategyGroup.category?.some((cate) => !!cate.usedBrand && cate.usedBrand.length !== 0)}
					eventKey="usedBrand"
					title={adCopyStrategyGroupCategoryLabels.usedBrand ?? ""}
				>
					<Row style={{ paddingLeft: "15px" }}>
						{strategyGroup.category?.map((category) => (
							<Col key={`usedBrand-category-${category.surveyChoice}`} md={5} style={{ paddingBottom: "1em" }}>
								{" "}
								{surveyChoiceMap.get(category.surveyChoice)}
								<AdCopyBrandSelect
									category={categoryMap[category.category._id?.toString() ?? ""]}
									filteredValue={category.unusedBrand}
									onChange={(values) => onChangeStrategyGroupCategory(category.surveyChoice, "usedBrand", values)}
									values={category.usedBrand}
								/>
							</Col>
						))}
					</Row>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
