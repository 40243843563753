import * as React from "react";
import styled from "styled-components";

import type { ErrorObject } from "../../../../../../../../react-components/lu-component/src/index";
import { ImageResizer } from "../../../../../parts/ad-copy/image-resizer";

// アップロード画像の最大サイズ（mb）
export const conceptImageMaxMb = 10;

type Props = {
	disabled: boolean;
	errors: ErrorObject | undefined;
	imageUrl: string;
	onChangeImageUrl: (value: string) => void;
};

const AlertMessage = styled.span`
	color: red;
`;

export const AdCopyConceptImageInput = React.memo((props: Props) => {
	const { disabled, errors, imageUrl, onChangeImageUrl } = props;

	return (
		<ImageResizer
			disabled={disabled}
			error={
				errors?.["imageUrl"]
					? typeof errors["imageUrl"] === "object"
						? errors["imageUrl"].toString()
						: "画像をアップロードしてください"
					: undefined
			}
			imageUrl={imageUrl}
			label={
				<>
					呈示素材登録
					<AlertMessage> ※必須　（{conceptImageMaxMb}MB以下の画像ファイルを登録してください）</AlertMessage>
				</>
			}
			onChangeImageUrl={onChangeImageUrl}
		/>
	);
});
