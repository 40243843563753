import * as React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../../routes/endpoint";

export type AdCopyNavigationBarProps = {
	account: AccountWithoutPassword;
	jobAction?: {
		onConceptPage: () => void;
		onJobEditPage: () => void;
		onPreviewPage: () => void;
	};
	onActivityPage: () => void;
	onJobDownloadPage?: () => void;
	onJobPage?: () => void;
	onJobSearchPage: () => void;
	onLogout: () => void;
	onMenuPage: () => void;
};

export const AdCopyNavigationBar = ({
	account,
	jobAction,
	onActivityPage,
	onJobDownloadPage,
	onJobPage,
	onJobSearchPage,
	onLogout,
	onMenuPage,
}: AdCopyNavigationBarProps) => {
	return (
		<Navbar bg="dark" className="px-3" expand="lg" fixed="top" variant="dark">
			<Navbar.Brand className="fw-bold">
				<NavDropdown title="Test it コピーテスト">
					<NavDropdown.Item href={clientEndpoint.activity}>1.コンセプトテスト</NavDropdown.Item>

					<NavDropdown.Item href={adCopyClientEndpoint.activity}>2.コピーテスト</NavDropdown.Item>
				</NavDropdown>
			</Navbar.Brand>

			<Navbar.Toggle aria-controls="basic-navbar-nav" />

			<Navbar.Collapse id="basic-navbar-nav" style={{ fontSize: 10.8 }}>
				<Nav className="me-auto">
					<Nav.Link onClick={onActivityPage}>アクティビティ一覧</Nav.Link>

					<Nav.Link onClick={onJobSearchPage}>ジョブ検索</Nav.Link>

					{onJobPage && <Nav.Link onClick={onJobPage}>ジョブ一覧に戻る</Nav.Link>}

					{jobAction && (
						<NavDropdown id="job" title="ジョブ設定">
							<NavDropdown.Item onClick={jobAction.onJobEditPage}>基本設定</NavDropdown.Item>

							<NavDropdown.Item onClick={jobAction.onConceptPage}>コピー設定</NavDropdown.Item>

							<NavDropdown.Item onClick={jobAction.onPreviewPage}>プレビュー</NavDropdown.Item>
						</NavDropdown>
					)}
				</Nav>

				<Nav>
					{onJobDownloadPage && <Nav.Link onClick={onJobDownloadPage}>ジョブDL</Nav.Link>}

					<Nav.Link onClick={onMenuPage}>
						<i className="bi bi-gear" style={{ fontSize: 12.8 }} />
					</Nav.Link>

					<Navbar.Text style={{ marginLeft: "20px", marginRight: "10px" }}>ログイン名: {account.name}さん</Navbar.Text>

					<Nav.Link onClick={onLogout}>Logout</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};
