import dayjs from "dayjs";

import { Brand } from "../../models/brand";
import { ObjectId } from "mongodb";
import { AdCopyProductType } from "../../models/ad-copy/activity";
import { AdCopyCategory, AdCopyCategoryRecognition } from "../../models/ad-copy/category";
import { AdCopyJob } from "../../models/ad-copy/job";
import { AdCopyConceptKeyForQuota } from "../../models/ad-copy/concept";
import { adCopyConceptNumberMap } from "./enquete/config";

export const deepCopy = <T>(data: T): T => {
	return JSON.parse(JSON.stringify(data));
};

export const isRenewal = (productType: keyof typeof AdCopyProductType): boolean => {
	return productType === "renewal1" || productType === "renewal2";
};

/**
 * 2値の比較
 * fromとtoがないケースがあるので、ない場合は最小値を0最大値を1000000000と仮置きしてチェックするようにする。
 * @param { {from?: number , to?:number} } a 比較1
 * @param { {from?: number , to?:number} } b 比較2
 * @returns { boolean }
 */
export const isCover = (a: { from?: number; to?: number }, b: { from?: number; to?: number }): boolean => {
	const { from: a1 = 0, to: a2 = 1000000000 } = a;
	const { from: b1 = 0, to: b2 = 1000000000 } = b;
	return b1 <= a2 && a1 <= b2;
};

export const endDay = 4;

export const makeAdCopyEndDate = (startDate: string): string => {
	const tmp = dayjs(startDate).add(endDay, "days");
	const endDate = tmp.minute() < 29 ? tmp.add(-1, "hours") : tmp;
	return endDate.format("YYYY-MM-DDTHH:") + "29";
};

export const replaceBreakAndSpace = (str: string): string => {
	return str.replace(/[\n\s]/g, "");
};

const maxRecognition = 100;

const makeMaxCategoryRecognition = (categoryRecognition: AdCopyCategoryRecognition[]): number => {
	return Math.min(maxRecognition, Math.round(Math.max(...categoryRecognition.map((t) => t.gAwareness))) + 10);
};
/**
 * カテゴリ×ブランドから最大設定可能認知率を取得する。
 * 上から優先して取得を行う。
 * 　0. まだアクティビティに紐づくブランドがない　　　　　　 : 100％
 * 　1. カテゴリ×ブランドで認知率がある　　　　　　　　　　　: ヒットした認知率を返却
 * 　2. 他カテゴリに対象ブランドが存在する　　　 　　　　　　: 他ブランドの最大の認知率 + 10
 * 　3. 他カテゴリに対象ブランドが存在しない（新規ブランド） : カテゴリ内のブランドの認知率 + 10
 * TODO: 3についての確認
 * @param {{ brandId?: string; category?: Category }} parameter 対象のアクティビティ
 * @param {Category[]} categories 全カテゴリのリスト
 * @param {Brand[]} brands 全ブランドのリスト
 * @returns {number}
 */
export const getAdCopyMaxRecognition = (
	parameter: Partial<{ brandId: string | ObjectId; category: AdCopyCategory }>,
	categories: AdCopyCategory[],
	brands: Brand[],
): number => {
	// 100%が最大
	if (!parameter.brandId || !parameter.category) return maxRecognition;
	const brandId = parameter.brandId.toString();
	const { category } = parameter;
	const brand = brands.find((b) => b._id.toString() === brandId);
	if (!brand) return maxRecognition;
	// nbrand=0は他ブランド
	// 1. カテゴリ×ブランドで認知率がある
	const filteredCategoryRecognition = category.categoryRecognition.filter(
		(cRecognition) => cRecognition.nBrand !== 0 && cRecognition.nBrand === brand.nBrand,
	);
	if (filteredCategoryRecognition.length) {
		return makeMaxCategoryRecognition(filteredCategoryRecognition);
	}

	// 2. 他カテゴリに対象ブランドが存在する
	/* No.9　商品認知率の初期値を当該ブランド×当該カテゴリがない場合、当該カテゴリの競合トップブランドから＋10pt以上の入力禁止となっているが、当該ブランド他カテゴリのトップから＋10pt以上の入力禁止とする。*/
	const filteredBrandRecognition: AdCopyCategoryRecognition[] = categories.reduce((a, { categoryRecognition }) => {
		// nbrand=0は他ブランド
		const tmp = categoryRecognition.filter(
			(cRecognition) => cRecognition.nBrand !== 0 && cRecognition.nBrand === brand.nBrand,
		);
		if (tmp.length) {
			return [...a, ...tmp];
		}
		return a;
	}, []);
	if (filteredBrandRecognition.length) {
		return makeMaxCategoryRecognition(filteredBrandRecognition);
	}
	// 3. 他カテゴリに対象ブランドが存在しない（新規ブランド）
	return makeMaxCategoryRecognition(category.categoryRecognition);
};

export const hasValue = (v: any): boolean => {
	if (v === undefined || v === null) return false;
	return true;
};

export const getRecognitionByCategoryAndBrand = (category: AdCopyCategory, brand: Brand): number | undefined => {
	const filteredCategoryRecognition = category.categoryRecognition.filter(
		(cRecognition) => cRecognition.nBrand !== 0 && cRecognition.nBrand === brand.nBrand,
	);
	if (filteredCategoryRecognition.length) {
		return Math.round(Math.max(...filteredCategoryRecognition.map((cRecognition) => cRecognition.gAwareness)));
	}
	return undefined;
};

export const isDefined = <T>(value: T | undefined | null): value is T => {
	if (value === undefined || value === null) return false;
	if (typeof value === "string" && value === "") return false;
	if (Array.isArray(value) && value.length === 0) return false;
	return true;
};

/**
 * ジョブ情報から対象のコンセプトタイプを取得する
 * @returns {(keyof typeof ConceptType)[]}
 */
export const getAdCopyJobConceptTypes = (job: AdCopyJob): AdCopyConceptKeyForQuota[] => {
	const conceptTypes: AdCopyConceptKeyForQuota[] = ["test1"];
	if (job.numOfTests >= 2) conceptTypes.push("test2");
	if (job.numOfTests >= 3) conceptTypes.push("test3");
	if (job.hasCurrentProduct) conceptTypes.push("currentProduct");
	return conceptTypes;
};

/**
 * Jobから対象のコンセプト番号を取得する
 */
export const getAdCopyConceptNumbers = (job: AdCopyJob): number[] => {
	const conceptNumbers: number[] = [adCopyConceptNumberMap.test1];
	if (job.numOfTests >= 2) conceptNumbers.push(adCopyConceptNumberMap.test2);
	if (job.numOfTests >= 3) conceptNumbers.push(adCopyConceptNumberMap.test3);
	if (job.hasCurrentProduct) conceptNumbers.push(adCopyConceptNumberMap.currentProduct);

	return conceptNumbers;
};
