import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyGroupCategoryLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import {
	adCopyBeautyTypeOptions,
	type AdCopyStrategyGroupCategory,
} from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopyCategory } from "../../../../../../../../server/models/ad-copy/category";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";
import { isSkincare } from "./utils";

type Props = {
	categoryMap: Record<string, AdCopyCategory>;
	onChangeStrategyGroupCategory: (surveyChoice: number, name: keyof AdCopyStrategyGroupCategory, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
	surveyChoiceMap: Map<number, string>;
};

export const AdCopyBeautyTypeForms = React.memo((props: Props) => {
	const { categoryMap, onChangeStrategyGroupCategory, strategyGroup, surveyChoiceMap } = props;

	const skincareCategoryGroups = React.useMemo(() => {
		if (!strategyGroup.category || strategyGroup.category.length === 0) {
			return [];
		}

		return strategyGroup.category.filter((category) => {
			const id = category.category._id?.toString() ?? "";

			if (!(id in categoryMap)) {
				return false;
			}

			return isSkincare(categoryMap[id].type);
		});
	}, [categoryMap, strategyGroup.category]);

	return (
		<Row>
			<FormGroup as={Col}>
				<AccordionForm
					defaultShow={strategyGroup.category?.some((cate) => !!cate.beautyType && cate.beautyType.length !== 0)}
					eventKey="beautifulSkinType"
					title={adCopyStrategyGroupCategoryLabels.beautyType ?? ""}
				>
					<Row style={{ paddingLeft: "15px" }}>
						{skincareCategoryGroups.map((category) => (
							<Col key={`beautyType-category-${category.surveyChoice}`} md={3} style={{ paddingBottom: "1em" }}>
								{surveyChoiceMap.get(category.surveyChoice)}

								<Checkboxes
									checkeds={category.beautyType ?? []}
									choices={adCopyBeautyTypeOptions as Choice[]}
									name={`${category.surveyChoice}-beautyType`}
									onChange={(v) => {
										onChangeStrategyGroupCategory(category.surveyChoice, "beautyType", v);
									}}
								/>
							</Col>
						))}
					</Row>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
