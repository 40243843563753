export const pastRate = 2.1;
export const rate = 3.2; // 利率の変更

// 切り替えタイミングのジョブ情報
const jobNums = ["21JP0073TC", "21JP0072TC", "21JP0071TC"];
const getRate = (jobNum: string): number => {
	if (jobNums.includes(jobNum)) {
		return pastRate;
	}
	return rate;
};
export const makeCost = (point: number, jobNum: string): number => {
	return Math.floor(point * getRate(jobNum));
};

export const aggregationCost = 10000;

export const decimalCalc = (input: number, decimalPoint = 10): number => {
	return Math.round(input * decimalPoint) / decimalPoint;
};
export const percentDecimalCalc = (input: number, decimalPoint = 10): number => {
	return Math.round(input * decimalPoint * 100) / decimalPoint;
};

export const decimalCalcJson = <T extends Object>(values: T, decimalPoint = 100): T => {
	return Object.entries(values).reduce((a, [key, value]) => {
		return {
			...a,
			[key]: decimalCalc(value, decimalPoint),
		};
	}, {}) as T;
};
