/* eslint-disable react/jsx-newline */

import type { ObjectId } from "mongodb";
import * as React from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import { adCopyActivityLabels } from "../../../../../../../label/ad-copy/activity";
import { type Option, Radios } from "../../../../../../../react-components/lu-component/src/index";
import { getAdCopyMaxRecognition } from "../../../../../../../server/lib/ad-copy/common";
import { AdCopyRole } from "../../../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../../../server/models/account";
import type { AdCopyGrp, AdCopySalesScale } from "../../../../../../../server/models/ad-copy/activity";
import {
	AdCopyGrpOptions,
	AdCopySalesScaleOptions,
	productTypeOptions,
} from "../../../../../../../server/models/ad-copy/activity";
import type { AdCopyCategory } from "../../../../../../../server/models/ad-copy/category";
import type { Brand } from "../../../../../../../server/models/brand";
import type { ErrorObject } from "../../../../../../../server/types/error";
import { castString } from "../../../../../lib/cast";
import type { TrueAdCopySimpleActivity, TrueAdCopyStrategyGroup } from "../../../../../types/ad-copy/activity";
import { AdCopyCategoryRecognitionTable } from "../../../../parts/ad-copy/recognition-table/category";
import { ConfirmButton } from "../../../../parts/confirm-dropdown-button";
import { AlertText, TextRed } from "../../../../parts/font";
import { Help } from "../../../../parts/help";
import { InputRow, ReactMultiSelectRow, SelectRow, TextRow } from "../../../../parts/input-row";
import { BrandRecognitionTable } from "../../../../parts/recognition-table/brand";
import { Title } from "../../../../parts/title";
import { AdCopyStrategicTargetForm } from "./strategic-target";
// import { AdCopyTargetCategoryBrandSelect } from "./target-category-brand-select";

const TargetArea = styled.div`
	margin-left: 30px;
`;

type Props = {
	accounts: AccountWithoutPassword[];
	activity: TrueAdCopySimpleActivity;
	brands: Brand[];
	cantEditMessage?: string;
	categories: AdCopyCategory[];
	errors: ErrorObject | undefined;
	loginAccount: AccountWithoutPassword;
	onChangeActivity: <T extends keyof TrueAdCopySimpleActivity>(name: T, value: TrueAdCopySimpleActivity[T]) => void;
	onChangeStrategyGroup: <T extends keyof TrueAdCopyStrategyGroup>(name: T, value: TrueAdCopyStrategyGroup[T]) => void;
	onSubmit: () => void;
	strategyTargetGroup: TrueAdCopyStrategyGroup;
};

export const AdCopyActivityCreatePage = React.memo((props: Props) => {
	const {
		accounts,
		activity,
		brands,
		cantEditMessage,
		categories,
		errors,
		loginAccount,
		onChangeActivity,
		onChangeStrategyGroup,
		onSubmit,
		strategyTargetGroup,
	} = props;

	// 担当アカウントで絞り込み。
	const brandholders = React.useMemo<Option[]>(() => {
		if (!activity.brandId) {
			return [];
		}

		const ids = activity.accountIds.map((id) => id.toString());

		return accounts
			.filter(
				(account) =>
					!!account.brand &&
					account.brand.includes(activity.brandId ?? "") &&
					loginAccount._id !== account._id &&
					AdCopyRole.isBrander(account) &&
					(account.isActive || ids.includes(account._id?.toString() ?? "")),
			)
			.map((account) => ({
				label: account.name,
				value: account._id?.toString() ?? "",
			}));
	}, [accounts, activity.accountIds, activity.brandId, loginAccount._id]);

	const categoryOptions = React.useMemo<Option[]>(
		() => categories.map((c) => ({ label: c.name, value: c._id?.toString() ?? "" })),
		[categories],
	);

	const brandOptions = React.useMemo<Option[]>(
		() => brands.map((brand) => ({ label: brand.name, value: brand._id?.toString() ?? "" })),
		[brands],
	);

	const onChangeCategory = React.useCallback(
		(value: ObjectId | string) => {
			const category = categories.find((cate) => cate._id === value);

			if (category) {
				onChangeActivity("category", category);
			}
		},
		[categories, onChangeActivity],
	);

	const selectedBrand = React.useMemo(() => {
		if (!activity.brandId) {
			return;
		}

		const brandId = activity.brandId.toString();

		return brands.find((b) => b._id?.toString() === brandId);
	}, [activity.brandId, brands]);

	const maxRate = React.useMemo(() => {
		const x = getAdCopyMaxRecognition(activity, categories, brands);

		return isFinite(x) ? x : 100;
	}, [activity, brands, categories]);

	const hasAlert = strategyTargetGroup.category != null && strategyTargetGroup.category.length >= 8;

	return (
		<>
			<div className="m-3">
				<div className="h3">■アクティビティ{activity._id ? "更新" : "新規登録"}</div>
			</div>

			<Container fluid>
				<Row>
					<Col md={12}>
						<Title>
							基本設定
							<Badge bg="danger" pill style={{ marginLeft: "10px" }}>
								必須
							</Badge>
						</Title>
					</Col>
				</Row>

				<Row>
					<Col md={11}>
						{/* ブランド */}
						<SelectRow
							errors={errors}
							id="activityBrand"
							name="brandId"
							onChange={(value) => {
								onChangeActivity("brandId", value);
							}}
							options={brandOptions}
							rowName={adCopyActivityLabels.brandId}
							value={castString(activity.brandId)}
						/>

						{/* 新規ブランド場合、ブランド名 */}
						{selectedBrand?.name.startsWith("新規ブランド") && (
							<TextRow
								errors={errors}
								id="brandName"
								name="brandName"
								onChange={(value) => {
									onChangeActivity("brandName", value);
								}}
								rowName={adCopyActivityLabels.brandName}
								value={activity.brandName ?? ""}
							/>
						)}

						{/* カテゴリ */}
						<SelectRow
							errors={errors}
							id="activityCategory"
							name="categoryId"
							onChange={onChangeCategory}
							options={categoryOptions}
							rowName={adCopyActivityLabels.category}
							value={castString(activity.category?._id)}
						/>

						{/* ターゲットカテゴリブランド */}
						{/* TODO: ラジオボタンにする？ */}
						{/* <AdCopyTargetCategoryBrandSelect activity={activity} onChangeActivity={onChangeActivity} /> */}

						{/* 他のアクティビティ登録者 */}
						<ReactMultiSelectRow
							errors={errors}
							id="activityAccounts"
							name="accountIds"
							onChange={(value) => {
								onChangeActivity("accountIds", value);
							}}
							options={brandholders}
							rowName={
								<>
									他のアクティビティ登録者
									<br />
									（複数選択可能）
									<Help
										explain="当アクティビティとそれに紐づくJOBを登録、更新、申請できる他のメンバーを選択してください。"
										id="accountIds"
									/>
									<Badge bg="primary" pill style={{ marginLeft: "10px" }}>
										任意
									</Badge>
								</>
							}
							values={activity.accountIds as string[]}
						/>

						{/* 上市/コピー使用開始時期（年） */}
						<TextRow
							errors={errors}
							id="releaseYear"
							name="releaseYear"
							onChange={(value) => {
								onChangeActivity("releaseYear", Number(value));
							}}
							rowName={
								<>
									{adCopyActivityLabels.releaseYear}

									<Help
										explain="新商品のコピー開発は上市時期を、既存品のコピー開発はコピー使用開始時期を入力してください"
										id="releaseYear"
									/>
								</>
							}
							type="number"
							value={activity.releaseYear ? activity.releaseYear.toString() : ""}
						/>

						{/* 上市/コピー使用開始時期（月） */}
						<SelectRow
							errors={errors}
							id="releaseMonth"
							name="releaseMonth"
							onChange={(value) => {
								onChangeActivity("releaseMonth", value ? Number(value) : undefined);
							}}
							options={[
								{ label: "1月", value: 1 },
								{ label: "2月", value: 2 },
								{ label: "3月", value: 3 },
								{ label: "4月", value: 4 },
								{ label: "5月", value: 5 },
								{ label: "6月", value: 6 },
								{ label: "7月", value: 7 },
								{ label: "8月", value: 8 },
								{ label: "9月", value: 9 },
								{ label: "10月", value: 10 },
								{ label: "11月", value: 11 },
								{ label: "12月", value: 12 },
							]}
							rowName={adCopyActivityLabels.releaseMonth}
							type="number"
							value={activity.releaseMonth ?? ""}
						/>

						{/* 開発区分 */}
						<SelectRow
							errors={errors}
							id="productType"
							name="productType"
							onChange={(value) => {
								onChangeActivity("productType", value);
							}}
							options={productTypeOptions}
							rowName={adCopyActivityLabels.productType}
							value={activity.productType ?? ""}
						/>

						{/* 売り上げ規模 */}
						<InputRow errors={errors} name="salesScale" rowName={adCopyActivityLabels.salesScale}>
							<Radios
								checked={activity.salesScale ?? ""}
								choices={AdCopySalesScaleOptions}
								name="salesScale"
								onChange={(value) => {
									onChangeActivity("salesScale", value as keyof typeof AdCopySalesScale);
								}}
							/>
						</InputRow>

						{/* TVCM規模（GRP) */}
						<InputRow errors={errors} name="grp" rowName={adCopyActivityLabels.grp}>
							<Radios
								checked={activity.grp ?? ""}
								choices={AdCopyGrpOptions}
								name="grp"
								onChange={(value) => {
									onChangeActivity("grp", value as keyof typeof AdCopyGrp);
								}}
							/>
						</InputRow>

						{/* テスト品想定認知率 */}
						<TextRow
							errors={errors}
							id="goalRecognitionRate"
							name="goalRecognitionRate"
							onChange={(value) => {
								onChangeActivity("goalRecognitionRate", Number(value));
							}}
							rowName={
								<div>
									日本人（当該性の）15～69歳における
									<br />
									想定商品認知率(%)<AlertText>（{maxRate}％以下の整数で入力）</AlertText>
									<Help explain="アウトプット時に、ポテンシャル需要の予測に使用されます" id="goalRecognitionRate" />
								</div>
							}
							subText={
								<>
									{selectedBrand && <BrandRecognitionTable brand={selectedBrand} />}

									{activity.category && <AdCopyCategoryRecognitionTable category={activity.category} />}
								</>
							}
							type="number"
							value={activity.goalRecognitionRate ? activity.goalRecognitionRate.toString() : ""}
						/>

						{/* 発売後12か月の目標お客さま購買 */}
						<TextRow
							errors={errors}
							id="goalSalesVolume"
							name="goalSalesVolume"
							onChange={(value) => {
								onChangeActivity("goalSalesVolume", value);
							}}
							rowName={
								<div>
									発売後12か月の目標お客さま購買
									<br />
									（万個）
									<Help
										explain="アウトプット時に、ポテンシャル需要予測結果のアクションスタンダードに使用されます。インバウンド等も含む、トータルの目標お客さま購買個数を入力します。小数点第1位まで入力できます。"
										id="goalSalesVolume"
									/>
								</div>
							}
							type="decimal"
							value={activity.goalSalesVolume ? activity.goalSalesVolume.toString() : ""}
						/>

						{/* 補足メモ */}
						<TextRow
							errors={errors}
							id="memo"
							name="memo"
							onChange={(value) => {
								onChangeActivity("memo", value);
							}}
							rowName={
								<>
									{adCopyActivityLabels.memo}

									<Help
										explain="アクティビティ一覧に反映されます。同発売時期、同カテゴリの商品を複数登録する場合など、アクティビティの区別のために必要な情報をメモできます。（20文字以内）"
										id="memo"
									/>

									<Badge bg="primary" pill style={{ marginLeft: "10px" }}>
										任意
									</Badge>
								</>
							}
							value={activity.memo ?? ""}
						/>
					</Col>
				</Row>
			</Container>

			<Container fluid>
				<>
					<Row>
						<Col md={12}>
							<Title>戦略ターゲットグループ定義</Title>
						</Col>
					</Row>

					<TargetArea>
						<AdCopyStrategicTargetForm
							categories={categories}
							errors={typeof errors?.["strategyTargetGroup"] === "object" ? errors["strategyTargetGroup"] : undefined}
							gender={activity.category?.gender}
							onChange={onChangeStrategyGroup}
							strategyGroup={strategyTargetGroup}
						/>
					</TargetArea>
				</>

				<Row>
					<Col md={{ offset: 4, span: 2 }}>
						{cantEditMessage ? (
							<Button className="w-100" disabled variant="secondary">
								{activity._id ? "更新" : "登録"}できません
							</Button>
						) : hasAlert ? (
							<ConfirmButton
								body={
									<TextRed>
										調査実施費用が100万円を超える可能性があります。
										<br />
										戦略ターゲットGの選択カテゴリに間違いがないか確認をお願いします。
									</TextRed>
								}
								buttonText={activity._id ? "更新" : "登録"}
								className="w-100"
								onOk={onSubmit}
								title={activity._id ? "更新" : "登録"}
								variant="secondary"
							/>
						) : (
							<Button className="w-100" onClick={onSubmit} variant="secondary">
								{activity._id ? "更新" : "登録"}
							</Button>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
});
